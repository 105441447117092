import { createApp } from 'vue'
import App from './App.vue'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import router from './router'

/* import the fontawesome core */
// import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
// import { fas } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
// library.add(fas)
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
// import {library} from '@fortawesome/fontawesome-svg-core';
// import { fas } from '@fortawesome/free-solid-svg-icons';

import { tooltip } from '../src/tooltip'


import * as Vue from 'vue' // in Vue 3
import VueApexCharts from "vue3-apexcharts";
export default {
  components: {
    apexchart: VueApexCharts,
  },
};
createApp(App)
// .component('font-awesome-icon', FontAwesomeIcon)
.use(VueApexCharts)
.use(router).mount('#app').app.directive('tooltip', tooltip);
