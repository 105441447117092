<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
        <div class="container-fluid">
            <a class="navbar-brand" href="#" style="color: #482f92;font-size: 15.4px;">
                <img src="../assets/logo.png" width="200">
                <br>
                PRIORITIZING ENERGY SOURCE
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item px-3 parallelogram" style="height:50px;">
                        <a class="nav-link active  pt-3" aria-current="page" href="#" style="transform: skew(20deg);">
                            <router-link to="/home" style="text-decoration: none;color: black;">Home</router-link>
                        </a>
                    </li>
                    <li class="nav-item px-3 parallelogram" style="height:50px;">
                        <a class="nav-link active  pt-3" aria-current="page" href="#" style="transform: skew(20deg);">
                            <router-link to="/chart" style="text-decoration: none;color: black;">Chart</router-link>
                        </a>
                    </li>
                    <li class="nav-item px-3 parallelogram" style="height:50px;">
                        <a class="nav-link active  pt-3" aria-current="page" href="#" style="transform: skew(20deg);">
                            <router-link to="/log" style="text-decoration: none;color: black;">Notification
                            </router-link>
                        </a>
                    </li>
                    <li class="nav-item px-3 parallelogram" style="height:50px;">
                        <a class="nav-link active  pt-3" aria-current="page" href="#" style="transform: skew(20deg);">
                            <router-link to="/setting" style="text-decoration: none;color: black;">Setting</router-link>
                        </a>
                    </li>
                    <li class="nav-item px-3 parallelogram" style="background-color: #482f92;height:50px;">
                        <a class="nav-link active text-white pt-3" aria-current="page" href="#"
                            style="transform: skew(20deg);">Profile</a>
                    </li>

                </ul>
                <form class="d-flex">

                    <button class="btn btn-outline-danger" type="submit" @click="logout()"><span>

                        </span> logout</button>
                </form>
            </div>
        </div>
    </nav>
    <div style="height: 85px;"></div>

    <body class="mx-2"  >
        <!-- <h3>Profile</h3> -->
        <div class="row justify-content-center">

          <div class="col-xl-5 col-lg-6 col-md-11 col-sm-11 col-12">
            <img style="width: 100%;" src="../assets/p.jpeg" alt="">
          </div>
            <div class="col-xl-5 col-lg-6 col-md-11 col-sm-11 col-12">
   <div class="row">
    <div class="col-3" style="min-width: 170px;">
<div style="font-weight:600;">Project Name</div>
<div  style="font-weight:600;">Location</div>
<div  style="font-weight:600;">Connecting Equipment</div>
<div  style="font-weight:600;">Installation Date</div>
        </div>
        <div class="col-7">
            <div>: Mali Resort (Pattaya Beach)</div>
<div>: 306, Koh Sarai, Koh Lipe, Satun 91000</div>
<div>: PCS,Generator</div>
<div>: 10/08/23</div>
        </div>  
   </div>
            </div>
        </div>
    </body>
</template>
<script>
    import {
        reactive
    } from '@vue/reactivity';

    import axios from 'axios';
    export default {

        data() {
            return {
                k: null,
                logNoti: [{
                        time: "",
                        message: ""
                    },
                    // {
                    //   time: "17/05/2023",
                    //   message: "Test Notification2"
                    // },
                ]
            };
        },
        methods: {
            //     log() {
            //       this.k="sss"
            //       var config = {
            //         headers: {
            //           token: localStorage.getItem("token"),
            //         }
            //       };
            //       axios.get('https://banpu-energy-api.sgroup.io/penergy/notifi', config)
            //       .then((response) => {
            //         console.log("testxs")
            //         // console.log(response.data)
            //         this.logNoti=response.data
            //         console.log(response.data)
            //         })
            //         .catch(function (error) {
            //           console.log(error);
            //         });
            //     },
            //     exportToCSV(data) {
            //     // const data = [
            //     //   { time_b: '2023-08-10 10:59:22', message: 'generator start' },
            //     //   { time_b: '2023-08-10 10:59:22', message: 'generator start' }
            //     // ];

            //     // Convert data to CSV format
            //     const csvContent = "time_b,message\n" + data.map(item => `${item.time_b},"${item.message}"`).join("\n");

            //     // Create a Blob with the CSV content
            //     const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

            //     // Create a URL for the Blob
            //     const url = URL.createObjectURL(blob);

            //     // Create a download link
            //     const a = document.createElement('a');
            //     a.href = url;
            //     a.download = 'data.csv';
            //     a.textContent = 'Download CSV';

            //     // Programmatically click the link to trigger the download
            //     a.click();

            //     // Clean up the URL object
            //     URL.revokeObjectURL(url);
            //   },
            //     logout() {
            //       localStorage.removeItem("token");
            //       localStorage.removeItem("role");
            //       this.$router.replace({
            //         path: "/login"
            //       });
            //     },
            //     checkStatusLogin() {
            //       try {
            //         var adminStatus = localStorage.getItem("token");
            //         if (adminStatus !== null) {
            //           try {
            //             this.checkLoginWithToken(adminStatus).then((statusCheck) => {
            //               if (statusCheck == true) {
            //                 this.$router.replace({
            //                   path: "/log"
            //                 });
            //               } else {
            //                 this.$router.replace({
            //                   path: "/login"
            //                 });
            //               }
            //             })
            //           } catch (error) {
            //             this.$router.replace({
            //               path: "/login"
            //             });
            //           }
            //         } else {
            //           this.$router.replace({
            //             path: "/login"
            //           });
            //         }
            //       } catch (error) {
            //         console.log(error);
            //         this.$router.replace({
            //           path: "/login"
            //         });
            //       }
            //     },

            //     checkLoginWithToken(token) {
            //       var config = {
            //         headers: {
            //           token: token,
            //         }
            //       };

            //       // Return a promise that will resolve to true or false based on the axios request
            //       return new Promise((resolve, reject) => {
            //         axios.get('https://banpu-energy-api.sgroup.io/penergy/auth/checktoken', config)
            //           .then(function (response) {
            //             console.log(response.data.data);
            //             if (response.data.data == "ok") {
            //               resolve(true);
            //             } else {
            //               resolve(false);
            //             }
            //           })
            //           .catch(function (error) {
            //             console.log(error);
            //             resolve(false); // Resolve the promise with false since login failed
            //           });
            //       });
            //     },
        },
        created() {
            // this.checkStatusLogin();
            // this.log();
        }
    };
</script>

<style>
    /* .parallelogram 
    {
  
  
        transform: skew(-20deg);
  
    }
      #circle {
        width: 50px;
        height: 50px;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        border-radius: 25px;
        background: red;
      } */
</style>