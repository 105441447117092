<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
    <div class="container-fluid">
      <a class="navbar-brand" href="#" style="color: #482f92;font-size: 15.4px;">
        <img src="../assets/logo.png" width="200">
        <br>
        PRIORITIZING ENERGY SOURCE
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item px-3 parallelogram" style="height:50px;">
            <a class="nav-link active  pt-3" aria-current="page" href="#" style="transform: skew(20deg);">
              <router-link to="/home" style="text-decoration: none;color: black;">Home</router-link>
            </a>
          </li>
          <li class="nav-item px-3 parallelogram" style="height:50px;">
            <a class="nav-link active  pt-3" aria-current="page" href="#" style="transform: skew(20deg);">
              <router-link to="/chart" style="text-decoration: none;color: black;">Chart</router-link>
            </a>
          </li>
          <li class="nav-item px-3 parallelogram" style="background-color: #482f92;height:50px;">
            <a class="nav-link active text-white pt-3" aria-current="page" href="#"
              style="transform: skew(20deg);">Notification</a>
          </li>
          <li class="nav-item px-3 parallelogram" style="height:50px;">
            <a class="nav-link active  pt-3" aria-current="page" href="#" style="transform: skew(20deg);">
              <router-link to="/setting" style="text-decoration: none;color: black;">Setting</router-link>
            </a>
          </li>
          <li class="nav-item px-3 parallelogram" style="height:50px;">
            <a class="nav-link active  pt-3" aria-current="page" href="#" style="transform: skew(20deg);">
              <router-link to="/profile" style="text-decoration: none;color: black;">Profile</router-link>
            </a>
          </li>
        </ul>
        <form class="d-flex">

          <button class="btn btn-outline-danger" type="submit" @click="logout()"><span>

            </span> logout</button>
        </form>
      </div>
    </div>
  </nav>
  <div style="height: 75px;"></div>

  <body class="mx-2">
    <h3>Notification Log <button type="button" class="btn btn-dark" @click="exportToCSV(logNoti)">Export Log.</button>
    </h3>
    <!-- <button @click="exportToCSV(logNoti)">exportToCSV</button> -->


    <div class="row justify-content-center mt-3">
      <div class="col-10 text-end mb-1" >
        Please select log type.
        <select v-model="selectedNoti" @change="calllogwithStatus(selectedNoti)">
          <option disabled value="">Please select</option>
          <option>All</option>
          <option>status</option>
          <option>fault</option>
        </select>
      </div>
      <table class="col-10 border">
        <tr class="text-white" style="font-size: 18px;background-color: gray;">
          <th style="width: 150px;"> Date Time</th>
          <th style="">Message</th>
          <th style="width: 150px;">Category</th>
        </tr>
        <!-- {{  }} -->
        <tr v-for="(item, index) in logNoti" :key="index">
          <td>
            {{item.time_b}}
          </td>
          <td>
            {{ item.message}}
          </td>
          <td>
            {{ item.category}}
          </td>
        </tr>
      </table>

    </div>
  </body>
</template>
<script>
  import {
    reactive
  } from '@vue/reactivity';

  import axios from 'axios';
  export default {

    data() {
      return {
        selectedNoti: "All",
        k: null,
        logNoti: [{
            time: "",
            message: ""
          },
          // {
          //   time: "17/05/2023",
          //   message: "Test Notification2"
          // },
        ]
      };
    },
    methods: {
      log() {
        this.k = "sss"
        var config = {
          headers: {
            token: localStorage.getItem("token"),
          }
        };
        axios.get('https://banpu-energy-api.sgroup.io/penergy/notifi', config)
          .then((response) => {
            console.log("testxs")
            var load = response.data;
            // console.log(response.data)
            // this.logNoti = response.data
            this.logNoti = load.reverse();
            // console.log(response.data)
          })
          .catch(function (error) {
            console.log(error);
          });
      },

      calllogwithStatus(status){
        if(status=="All"){
          this.log();
        }
        else{
          try {
               const article = {
                        "category":status
                        };
                        const headers = {
                            "Content-Type": "application/json",
                            "token": localStorage.getItem("token")
                        };
                        axios.post('https://banpu-energy-api.sgroup.io/penergy/notifi', article, {
                                headers
                            })
                            .then(response => {
                                var load = response.data;
                                this.logNoti = load.reverse();
                              //  console.log(load);
                            }).catch((e) => {
                              console.log(e)
                            });
                    } catch (e) {};
        }

      },
      exportToCSV(data) {
        // const data = [
        //   { time_b: '2023-08-10 10:59:22', message: 'generator start' },
        //   { time_b: '2023-08-10 10:59:22', message: 'generator start' }
        // ];

        // Convert data to CSV format
        const csvContent = "time_b,message,category\n" + data.map(item => `${item.time_b},"${item.message}","${item.category}"`).join("\n");

        // Create a Blob with the CSV content
        const blob = new Blob([csvContent], {
          type: 'text/csv;charset=utf-8;'
        });

        // Create a URL for the Blob
        const url = URL.createObjectURL(blob);

        // Create a download link
        const a = document.createElement('a');
        a.href = url;
        a.download = 'data.csv';
        a.textContent = 'Download CSV';

        // Programmatically click the link to trigger the download
        a.click();

        // Clean up the URL object
        URL.revokeObjectURL(url);
      },
      logout() {
        localStorage.removeItem("token");
        localStorage.removeItem("role");
        this.$router.replace({
          path: "/login"
        });
      },
      checkStatusLogin() {
        try {
          var adminStatus = localStorage.getItem("token");
          if (adminStatus !== null) {
            try {
              this.checkLoginWithToken(adminStatus).then((statusCheck) => {
                if (statusCheck == true) {
                  this.$router.replace({
                    path: "/log"
                  });
                } else {
                  this.$router.replace({
                    path: "/login"
                  });
                }
              })
            } catch (error) {
              this.$router.replace({
                path: "/login"
              });
            }
          } else {
            this.$router.replace({
              path: "/login"
            });
          }
        } catch (error) {
          console.log(error);
          this.$router.replace({
            path: "/login"
          });
        }
      },

      checkLoginWithToken(token) {
        var config = {
          headers: {
            token: token,
          }
        };

        // Return a promise that will resolve to true or false based on the axios request
        return new Promise((resolve, reject) => {
          axios.get('https://banpu-energy-api.sgroup.io/penergy/auth/checktoken', config)
            .then(function (response) {
              console.log(response.data.data);
              if (response.data.data == "ok") {
                resolve(true);
              } else {
                resolve(false);
              }
            })
            .catch(function (error) {
              console.log(error);
              resolve(false); // Resolve the promise with false since login failed
            });
        });
      },
    },
    created() {
      this.checkStatusLogin();
      this.log();
    }
  };
</script>

<style>
  /* .parallelogram 
  {


      transform: skew(-20deg);

  }
    #circle {
      width: 50px;
      height: 50px;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      border-radius: 25px;
      background: red;
    } */
</style>