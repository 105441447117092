<template>
  <router-view />
</template>

<script>
import { DatePickerComponent } from "@syncfusion/ej2-vue-calendars";
export default {

  name: 'App',
  components: {
    'ejs-datepicker' : DatePickerComponent,
  },
   
}

</script>
<style>
    @import '../node_modules/@syncfusion/ej2-base/styles/material.css';
    @import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
    @import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
    @import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
    @import "../node_modules/@syncfusion/ej2-vue-calendars/styles/material.css";
    .control_wrapper {
        max-width: 250px;
        margin: 0 auto;
    }
</style>

   
<style>
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@300&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Kanit:wght@600&display=swap');

html, body,a,div,table,tr,td {
  font-family: 'Kanit', sans-serif;
  font-size: 14px;
}

#app {
  font-family: 'Kanit', sans-serif;
}
</style>