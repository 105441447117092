<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
    <div class="container-fluid">
      <a class="navbar-brand" href="#" style="color: #482f92;font-size: 15.4px;">
        <img src="../assets/logo.png" width="200">
        <br>
        PRIORITIZING ENERGY SOURCE
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item px-3 parallelogram" style="height:50px;">
            <a class="nav-link active  pt-3" aria-current="page" href="#" style="transform: skew(20deg);">
              <router-link to="/home" style="text-decoration: none;color: black;">Home</router-link>
            </a>
          </li>
          <li class="nav-item px-3 parallelogram" style="height:50px;">
            <a class="nav-link active  pt-3" aria-current="page" href="#" style="transform: skew(20deg);">
              <router-link to="/chart" style="text-decoration: none;color: black;">Chart</router-link>
            </a>
          </li>
          <li class="nav-item px-3 parallelogram" style="height:50px;">
            <a class="nav-link active  pt-3" aria-current="page" href="#" style="transform: skew(20deg);">
              <router-link to="/log" style="text-decoration: none;color: black;">Notification
              </router-link>
            </a>
          </li>
          <li class="nav-item px-3 parallelogram" style="height:50px;background-color: #482f92;">
            <a class="nav-link active text-white pt-3" aria-current="page" href="#" style="transform: skew(20deg);">
              <router-link to="/setting" class="text-white" style="text-decoration: none;">Setting</router-link>
            </a>
          </li>
          <li class="nav-item px-3 parallelogram" style="height:50px;">
            <a class="nav-link active  pt-3" aria-current="page" href="#" style="transform: skew(20deg);">
              <router-link to="/profile" style="text-decoration: none;color: black;">Profile</router-link>
            </a>
          </li>

        </ul>
        <form class="d-flex">

          <button class="btn btn-outline-danger" type="submit" @click="logout()"><span>

            </span> logout</button>
        </form>
      </div>
    </div>
  </nav>


  <body v-if="adminStatus==true" class="mx-2">
    <div style="height: 70px;"></div>
    <h3>Setting</h3>
    <!-- <div>{{ pre_setting }}</div> -->
    <div v-if="statusSocket==true" class="row justify-content-center mt-3">
      <div class="col-10 border" style="">
        <h5 class="mt-3">Generator warm up time :</h5>
        <input type="number" min="0" max="10" step="1" style="height: 36px;font-size: 20px;" disabled />
        &nbsp;
        <input v-model="pre_setting.gen_warmup.m" type="number" min="0" max="60" step="1"
          style="height: 36px;font-size: 20px;" />
        <div class="col-1 ms-1 " style="min-width: 100px;">
          <div class="row">
            <div class="col-6 ">Hr.</div>
            <div class="col-6 ">Min.</div>
          </div>
        </div>
        <div>
          <i style="color: grey;">description: Generator prepareing status</i>
        </div>

        <hr>

        <h5>Generator minimum run time:</h5>
        <input v-model="pre_setting.gen_worktime.h" type="number" min="0" max="23" step="1"
          style="height: 36px;font-size: 20px;" />
        &nbsp;
        <input v-model="pre_setting.gen_worktime.m" type="number" min="0" max="60" step="1"
          style="height: 36px;font-size: 20px;" />
        <div class="col-1 ms-1 " style="min-width: 100px;">
          <div class="row">
            <div class="col-6 ">Hr.</div>
            <div class="col-6 ">Min.</div>
          </div>
        </div>
        <div>
          <i style="color: grey;">description: Generator on use time</i>
        </div>

        <hr>
        <h5>Generator Peak Current Start:</h5>
        <input v-model="pre_setting.current_start" type="number" min="0" max="100" step="1"
          style="height: 36px;font-size: 20px;" />

        <div class="col-1 ms-1 " style="min-width: 100px;">
          <div class="row">
            <div class="col-6 ">Amp(A).</div>
          </div>
        </div>
        <div>
          <i style="color: grey;">description: </i>
        </div>

        <hr>
        <h5>Generator Min Current Swing:</h5>
        <input v-model="pre_setting.current_swing" type="number" min="0" max="100" step="1"
          style="height: 36px;font-size: 20px;" />

        <div class="col-1 ms-1 " style="min-width: 100px;">
          <div class="row">
            <div class="col-6 ">Amp(A).</div>
          </div>
        </div>
        <div>
          <i style="color: grey;">description: </i>
        </div>

        <hr>
        <h5>Time Count Current Swing of Generator:</h5>
        <input v-model="pre_setting.time_check_current" type="number" min="0" max="100" step="1"
          style="height: 36px;font-size: 20px;" />

        <div class="col-1 ms-1 " style="min-width: 100px;">
          <div class="row">
            <div class="col-6 ">sec(s).</div>
          </div>
        </div>
        <div>
          <i style="color: grey;">description: </i>
        </div>

        <!-- <hr> -->

        <!-- <h5>Grid workload time when inverter and generator is status not working:</h5>
        <input  type="number" min="0" max="10" step="1" style="height: 36px;font-size: 20px;" />
        &nbsp;
        <input  type="number" min="0" max="10" step="1" style="height: 36px;font-size: 20px;" />
        <div>
          <i style="color: grey;">description: Grid workload time </i>
        </div> -->
        <div class="text-center my-4">
          <button type="button" class="btn btn-outline-btSave" data-bs-toggle="modal"
            data-bs-target="#allSettingModal">Save Change</button>
        </div>


      </div>
    </div>

    <div  style="height: 25vh;"></div>
        <div v-if="statusSocket==false" class="row justify-content-center">
            <div class="loader"></div>
            <div class="text-center mt-3  ">Loading...</div>
    </div>




    <div class="modal fade" id="allSettingModal" tabindex="-1" aria-labelledby="allSettingModalLabel"
      aria-hidden="true">
      <div class="modal-dialog  modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="allSettingModalLabel">CONFIRM SETTING</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            Do you want to save change.?
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-outline-btSave" @click="settingDevice()" data-bs-dismiss="modal">Save
              changes</button>
          </div>
        </div>
      </div>
    </div>
  </body>

  <body v-else>
    <div style="height: 300px;"></div>
    <h4 class="text-center ">
      Please Login By Admin.
    </h4>
  </body>
</template>
<script>
  import {
    socket
  } from "../services/socket.js";
  import axios from 'axios'
  import {
    reactive
  } from 'vue';
  export default {

    data() {
      return {
        BufferSetting: [],
        statusSocket:false,

        pre_setting: {
          gen_warmup: {
            h: 0,
            m: 0
          },
          gen_worktime: {
            h: 0,
            m: 0
          },
          current_start: 0.0,
          current_swing: 0.0,
          time_check_current: 0,
        },

        setting: {
          gen_warmup: 0.0,
          gen_worktime: 0.0,
          current_start: 0.0,
          current_swing: 0.0,
          time_check_current: 0,
        },
        adminStatus: false,
      };
    },
    methods: {
      checkAdmin() {
        var role = localStorage.getItem("role");
        if (role == "Admin") {
          this.adminStatus = true
        } else {
          this.adminStatus = false
        }
      },
      historySettingDevice() {
        var config = {
          headers: {
            token: localStorage.getItem("token"),
          }
        };
        axios.get('https://banpu-energy-api.sgroup.io/penergy/config', config)
          .then(function (response) {
            // console.log(response.data);
            // var hrGen_warmup = Math.floor(response.data.setting.gen_warmup);
            var minGen_warmup = response.data.setting.gen_warmup;
            var hrGen_worktime = Math.floor(response.data.setting.gen_worktime);
            var minGen_worktime = Math.round((response.data.setting.gen_worktime - hrGen_worktime) * 60);

            this.pre_setting.gen_warmup.h = hrGen_warmup;
            this.pre_setting.gen_warmup.m = minGen_warmup;

            this.pre_setting.gen_worktime.h = hrGen_worktime;
            this.pre_setting.gen_worktime.m = minGen_worktime;

            this.pre_setting.current_start = response.data.setting.current_start;
            this.pre_setting.current_swing = response.data.setting.current_swing;
            this.pre_setting.time_check_current = response.data.setting.time_check_current;
          })
          .catch(function (error) {
            // console.log(error);
          });
      },
      logout() {
        localStorage.removeItem("token");
        localStorage.removeItem("role");
        this.$router.replace({
          path: "/login"
        });
      },
      settingDevice() {
        var config = {
          headers: {
            token: localStorage.getItem("token"),
          }
        };
        axios.post('https://banpu-energy-api.sgroup.io/penergy/config', {
              setting: {
                gen_warmup: this.pre_setting.gen_warmup.m,
                gen_worktime: (this.pre_setting.gen_worktime.h * 60) + this.pre_setting.gen_worktime.m,
                current_start: this.pre_setting.current_start,
                current_swing: this.pre_setting.current_swing,
                time_check_current: this.pre_setting.time_check_current
              }
            },
            config
          )
          .then((response) => {
            console.log(response.data);
            // var hrGen_warmup = Math.floor(response.data.setting.gen_warmup);
            var minGen_warmup = response.data.setting.gen_warmup;
            // var hrGen_worktime = Math.floor(response.data.setting.gen_worktime);
            // var minGen_worktime = Math.round((response.data.setting.gen_worktime - hrGen_worktime) * 60);
            var hrGen_worktime = Math.floor(response.data.setting.gen_worktime / 60);
            var minGen_worktime = (response.data.setting.gen_worktime % 60);

            // this.pre_setting.gen_warmup.h = hrGen_warmup;
            this.pre_setting.gen_warmup.m = minGen_warmup;

            this.pre_setting.gen_worktime.h = hrGen_worktime;
            this.pre_setting.gen_worktime.m = minGen_worktime;

            this.pre_setting.current_start = response.data.setting.current_start;
            this.pre_setting.current_swing = response.data.setting.current_swing;
            this.pre_setting.time_check_current = response.data.setting.time_check_current;
          })
          .catch((error) => {
            // console.error(error);
          });
      },

      checkStatusLogin() {
        try {
          var adminStatus = localStorage.getItem("token");
          if (adminStatus !== null) {
            try {
              this.checkLoginWithToken(adminStatus).then((statusCheck) => {
                if (statusCheck == true) {
                  this.$router.replace({
                    path: "/setting"
                  });
                } else {
                  this.$router.replace({
                    path: "/login"
                  });
                }
              })
            } catch (error) {
              this.$router.replace({
                path: "/login"
              });
            }
          } else {
            this.$router.replace({
              path: "/login"
            });
          }
        } catch (error) {
          console.log(error);
          this.$router.replace({
            path: "/login"
          });
        }
      },

      checkLoginWithToken(token) {
        var config = {
          headers: {
            token: token,
          }
        };

        // Return a promise that will resolve to true or false based on the axios request
        return new Promise((resolve, reject) => {
          axios.get('https://banpu-energy-api.sgroup.io/penergy/auth/checktoken', config)
            .then(function (response) {
              // console.log(response.data.data);
              if (response.data.data == "ok") {
                resolve(true);
              } else {
                resolve(false);
              }
            })
            .catch(function (error) {
              // console.log(error);
              resolve(false); // Resolve the promise with false since login failed
            });
        });
      },

    },
    created() {
      this.checkStatusLogin();
      this.checkAdmin();
      socket.on("data", (data) => {
        this.BufferSetting = data.data.setting_config
        var response= data.data.setting_config;
        // console.log(data.data);
        try {
          if (this ?.BufferSetting&&this.statusSocket==false) {

            this.statusSocket=true;
            // console.log(response.setting)
            var minGen_warmup = response.setting.gen_warmup;

            var hrGen_worktime = Math.floor(response.setting.gen_worktime / 60);
            var minGen_worktime = (response.setting.gen_worktime) % 60;
            

            this.pre_setting.gen_warmup.m = minGen_warmup;

            this.pre_setting.gen_worktime.h = hrGen_worktime;
            this.pre_setting.gen_worktime.m = minGen_worktime;

            this.pre_setting.current_start = response.setting.current_start;
            this.pre_setting.current_swing = response.setting.current_swing;
            this.pre_setting.time_check_current = response.setting.time_check_current;
            // this.realtime_value = reactive(data.data.realtime_value)
          }
        } catch (error) {

        }
      }, );
    
      // this.historySettingDevice();
    },
    mounted() {
      // socket.on("data", (data) => {
      //   this.BufferSetting = data.data.setting_config
      //   var response= data.data.setting_config;
      //   // console.log(data.data.setting_config.);
      //   try {
      //     if (this ?.BufferSetting) {

      //       this.statusSocket=true;
      //       console.log(response.setting)
      //       var minGen_warmup = response.setting.gen_warmup;

      //       var hrGen_worktime = Math.floor(response.setting.gen_worktime / 60);
      //       var minGen_worktime = (response.setting.gen_worktime) % 60;
            

      //       this.pre_setting.gen_warmup.m = minGen_warmup;

      //       this.pre_setting.gen_worktime.h = hrGen_worktime;
      //       this.pre_setting.gen_worktime.m = minGen_worktime;

      //       this.pre_setting.current_start = response.setting.current_start;
      //       this.pre_setting.current_swing = response.setting.current_swing;
      //       this.pre_setting.time_check_current = response.setting.time_check_current;
      //       // this.realtime_value = reactive(data.data.realtime_value)
      //     }
      //   } catch (error) {

      //   }
      // }, );
    
    }
  };
</script>

<style>
  .parallelogram {
    transform: skew(-20deg);
  }

  #circle {
    width: 50px;
    height: 50px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
    background: red;
  }

  .btn-outline-btSave {
    --bs-btn-color: #482f92;
    --bs-btn-border-color: #482f92;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #482f92;
    --bs-btn-hover-border-color: #482f92;
    --bs-btn-focus-shadow-rgb: 13, 110, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #482f92;
    --bs-btn-active-border-color: #482f92;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #482f92;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #482f92;
    --bs-gradient: none;
  }
</style>