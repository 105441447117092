<template>

    <body>
        <div style="height: 300px;"></div>
        <div class="row justify-content-center">
            <div class="loader"></div>
        </div>
    </body>
</template>

<script>
    import axios from 'axios'
    
    export default {
        data() {
            return {
                data: '',
                ErrorMessage: ''
            };
        },
        methods: {
            checkStatusLogin() {
                try {
                    var adminStatus = localStorage.getItem("token");
                    if (adminStatus !== null) {
                        try {
                            this.checkLoginWithToken(adminStatus).then((statusCheck) => {
                                if (statusCheck == true) {
                                    this.$router.replace({
                                        path: "/home"
                                    });
                                } else {
                                    this.$router.replace({
                                        path: "/login"
                                    });
                                }
                            })
                        } catch (error) {
                            this.$router.replace({
                                path: "/login"
                            });
                        }
                    } else {
                        this.$router.replace({
                            path: "/login"
                        });
                    }
                } catch (error) {
                    console.log(error);
                    this.$router.replace({
                            path: "/login"
                        });
                }
            },

            checkLoginWithToken(token) {
                var config = {
                    headers: {
                        token: token,
                    }
                };

                // Return a promise that will resolve to true or false based on the axios request
                return new Promise((resolve, reject) => {
                    axios.get('https://banpu-energy-api.sgroup.io/penergy/auth/checktoken', config)
                        .then(function (response) {
                            console.log(response.data.data);
                            if (response.data.data == "ok") {
                                resolve(true);
                            } else {
                                resolve(false);
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                            resolve(false); // Resolve the promise with false since login failed
                        });
                });
            },
        },
        created() {
            this.checkStatusLogin();
            // console.log("/api")
        },
    };
</script>
<style>
    .loader {
        border: 16px solid #f3f3f3;
        /* Light grey */
        border-top: 16px solid #482f92;
        /* Blue */
        border-radius: 50%;
        width: 120px;
        height: 120px;
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
</style>