<template>
  <!DOCTYPE html>
  <html lang="en">

  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
  </head>
  <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
    <div class="container-fluid">
      <a class="navbar-brand" href="#" style="color: #482f92;font-size: 15.4px;">
        <img src="../assets/logo.png" width="200">
        <br>
        PRIORITIZING ENERGY SOURCE
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item px-3 parallelogram" style="background-color: #482f92;height:50px;">
            <a class="nav-link active text-white pt-3" aria-current="page" href="#"
              style="transform: skew(20deg);">Home</a>
          </li>
          <li class="nav-item px-3 parallelogram" style="height:50px;">
            <a class="nav-link active  pt-3" aria-current="page" href="#" style="transform: skew(20deg);">
              <router-link to="/chart" style="text-decoration: none;color: black;">Chart</router-link>
            </a>
          </li>
          <li class="nav-item px-3 parallelogram" style="height:50px;">
            <a class="nav-link active  pt-3" aria-current="page" href="#" style="transform: skew(20deg);">
              <router-link to="/log" style="text-decoration: none;color: black;">Notification</router-link>
            </a>
          </li>
          <li class="nav-item px-3 parallelogram" style="height:50px;">
            <a class="nav-link active  pt-3" aria-current="page" href="#" style="transform: skew(20deg);">
              <router-link to="/setting" style="text-decoration: none;color: black;">Setting</router-link>
            </a>
          </li>
          <li class="nav-item px-3 parallelogram" style="height:50px;">
            <a class="nav-link active  pt-3" aria-current="page" href="#" style="transform: skew(20deg);">
              <router-link to="/profile" style="text-decoration: none;color: black;">Profile</router-link>
            </a>
          </li>
        </ul>
        <form class="d-flex">
          <button class="btn btn-outline-danger" type="submit" @click="logout()"><span>
            </span> logout</button>
        </form>
      </div>
    </div>
  </nav>

  <div class="mt-2" style="height: 60px;">
    <div class="ripple"></div>
  </div>

  <body class="">
    <div class="row justify-content-center  px-3">
      <div class="col-xl-6 col-lg-7 col-md-10 col-sm-10 col-12 mt-4">
        <div class="row">
          <!-- <div class="col-1 "></div> -->
          <u class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4 text-start"
            style="font-size: 12px;max-width: 145px;">MCU STATUS:</u>
          <div v-if="realtime_value.mcu.status==1" class="col-5 text-start" style="font-size: 12px;color: #2f928a;">
            &#11044;<span style="color: #646464;">
              Online</span></div>
          <div v-else class="col-6 text-start" style="font-size: 12px;color: #cc5050;">&#11044;<span
              style="color: #646464;">
              Offline</span></div>
        </div>
        <div class="row">
          <!-- <div class="col-1 "></div> -->
          <u class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4 text-start"
            style="font-size: 12px;max-width:145px; ">MICROGRID STATUS:</u>
       
          <div v-if="realtime_value.grid.status==0&&realtime_value.inverter.status==0" class="col-6 text-start" style="font-size: 12px;color: #cc5050;">&#11044;<span
              style="color: #646464;">
              Fault</span></div>
          <div v-else class="col-5 text-start" style="font-size: 12px;color: #2f928a;">
            &#11044;<span style="color: #646464;">
              Normal</span></div>
        </div>
        <div class="row">
          <u class="col-xl-3 col-lg-4 col-md-3 col-sm-3 col-4  text-start" style="font-size: 12px;max-width: 145px;">MCU RUN
            TIME :</u>
          <div class="col-6 text-start" style="font-size: 12px;color: #2f928a;">
            <span style="color: #646464;">
              {{ realtime_value.runtime.mcu.runtimemcu_day }} Day :{{ realtime_value.runtime.mcu.runtimemcu_hr }} Hour :{{ realtime_value.runtime.mcu.runtimemcu_min }} Minute
            </span>
            </div>
        </div>

        <div class="row">
          <u class="col-xl-3 col-lg-4 col-md-3 col-sm-3 col-4  text-start" style="font-size: 12px;max-width: 145px;">MICROGRID DOWNTIME:</u>
          <div class="col-6 text-start" style="font-size: 12px;color: #2f928a;">
            <span style="color: #646464;">
              {{ realtime_value.runtime.pcs.fault_day }} Day :{{ realtime_value.runtime.pcs.fault_hr }} Hour :{{ realtime_value.runtime.pcs.fault_min }} Minute
            </span>
            </div>
        </div>
        
        <div class="row mt-3">
          <table>
            <tr>
              <td>
              </td>
              <td></td>
              <td style="width: 100px;" class="text-center">
                <div v-if="realtime_value.grid.status==0">
                  <img src="../assets/pole1.png" style="width: 100px;" alt="">
                  <div class="text-center" style="font-size:12px;">GRID
                  </div>
                </div>
                <div v-else-if="realtime_value.grid.status==1">
                  <div class="">
                    <div class="circlemini">
                      <img src="../assets/pole1.png" style="width: 100px;">
                      <div class="loading-circle-mini"></div>
                    </div>
                  </div>
                  <div class="text-center" style="font-size:12px;">GRID
                  </div>
                </div>
                <div v-else>
                  <div style="width: 100px;"></div>
                </div>
              </td>

              <td v-if="realtime_value.inverter.status==2&&realtime_value.ats.status==1" class="text-center "
                style="width: 100px; color: #2f928a;font-size: 20px;"><span class="dot-animation dotanimation">
                  <span style="font-size: 20px;"> &#x25B8; &#x25B8;</span>
                </span>
              </td>
              <td v-else class="text-center " style="width: 100px; color: #2f928a;font-size: 20px;"><span
                  class="dot-animation dotanimation">
                  <span>&nbsp;&nbsp;&nbsp;</span>
                </span></td>


              <td v-if="realtime_value.gen.status==0" class="text-center" style="width: 100px;font-size: 12px;">
                <div v-if="realtime_value.inverter.status==0" class="mb-2">
                  <div class="circle">
                    <img src="../assets/trace.svg">
                    <div class=""></div>
                  </div>
                </div>
                <div v-else-if="realtime_value.inverter.status==1" class="circle-wrapper mb-2">
                  <div class="circle">
                    <img src="../assets/trace.svg">
                    <div class="loading-circle"></div>
                  </div>
                </div>
                <div v-else-if="realtime_value.inverter.status==2" class="circle-wrapper mb-2">
                  <div class="circle">
                    <img src="../assets/trace.svg">
                    <div class="loading-circle"></div>
                  </div>
                </div>
                <div v-if="realtime_value.inverter.status==3" class="circle-wrapper">
                  <div class="circle2">
                    <img src="../assets/trace2.svg">
                    <div class=""></div>
                  </div>
                  <div class="text-center" style="font-size:9px;color: #FA7667;">PCS LOST</div>
                </div>
                <div class="text-center" style="font-size:12px;">MODE: {{realtime_value.selected_mode.mode}} 
                </div>
              </td>
              <td v-else class="text-center" style="width: 100px;font-size: 12px;">
                <div  class="mb-1">
                  <div class="circlemini">
                    <img src="../assets/load1_svg.svg">
                    <div class=""></div>
                  </div>
                </div>
               
                <div class="text-center" style="font-size:12px;">MODE: {{realtime_value.selected_mode.mode}} 
                </div>
              </td>

              <!-- <td class="text-center " style="width: 100px; color: #2f928a;font-size: 20px;"><span
                  class="dot-animation dotanimation">
                  <span style="font-size: 20px;"> &#x25C2;  &#x25C2;</span>
                </span></td> -->

              <td v-if="realtime_value.selected_mode.mode=='GENERATOR'" class="text-center "
                style="width: 100px; color: #2f928a;font-size: 20px;"><span class="dot-animation dotanimation">
                  <span style="font-size: 25px;"> &#x25C2; &#x25C2;</span>
                </span>
              </td>
              <td v-else class="text-center " style="width: 100px; color: #2f928a;font-size: 20px;"><span
                  class="dot-animation dotanimation">
                  <span>&nbsp;&nbsp;&nbsp;</span>
                </span></td>

              <td style="width: 100px;" class="text-center">
                <div v-if="realtime_value.gen.status==0">
                  <img src="../assets/load1_svg.svg" style="width: 100px;" alt="">
                  <div class="text-center" style="font-size:12px;">GENERATOR
                  </div>
                </div>

                <div v-else-if="realtime_value.gen.status==1">
                  <div class="">
                    <div class="circlemini">
                      <img src="../assets/load1_svg.svg">
                      <div class="loading-circle-mini"></div>
                    </div>
                  </div>
                  <div class="text-center" style="font-size:12px;">GENERATOR
                  </div>
                </div>
                <div v-else-if="realtime_value.gen.status==3">
                  <div class="">
                    <div class="circlemini">
                      <img src="../assets/load2_svg.svg">
                      <div class=""></div>
                    </div>
                  </div>
                  <div class="text-center" style="font-size:9px;color: #FA7667;">GENERATOR LOST</div>
                  <div class="text-center" style="font-size:12px;">GENERATOR
                  </div>
                </div>
                <div v-else>
                </div>
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td class="text-center">
                <div class="dot-animation dotanimation" style="font-size: 12px;">&#x25BC;</div>
                <div class="dot-animation dotanimation" style="font-size: 12px;">&#x25BC;</div>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td style="width: 100px;" class="text-center">
                <div v-if="power.status==0">
                  <div class="circlemini">
                    <img src="../assets/meter1.png">
                    <div class="loading-circle-mini"></div>
                  </div>
                </div>

                <div v-if="power.status==1">
                  <div class="circlemini">
                    <img src="../assets/meter1.svg">
                    <div class="loading-circle-mini"></div>
                  </div>
                  <div class="text-center" style="font-size:9px;color: #FA7667;">OVERLOAD CURRENT</div>
                </div>
                <div class="text-center mt-1" style="font-size:12px;"> POWER METER &nbsp;</div>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td class="text-center">
                <div class="dot-animation dotanimation" style="font-size: 12px;">&#x25BC;</div>
                <div class="dot-animation dotanimation" style="font-size: 12px;">&#x25BC;</div>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td style="width: 100px;" class="text-center">
                <div class="">
                  <div class="circlemini">
                    <img src="../assets/load1.png">
                    <div class="loading-circle-mini"></div>
                  </div>
                </div>
                <div class="text-center mt-1" style="font-size:12px;">LOAD &nbsp;</div>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </table>
        </div>
      </div>
      <div class="ms-2 mt-3 col-xl-4 col-lg-4 col-md-10  col-sm-10 col-12 ">
        <div style="font-size: 20px;">
          Load Consumption
        </div>
        <div class=" mt-2 ">
          <span >Voltage <span class="hover1_1"><span class="hover1 "><img src="../assets/i.png" width="20" alt=""></span></span></span>
          <div class="row">
            <div class="col-4 text-center">
              <div style="font-size: 24px;color:#3ED79D">{{ `${two_digit(realtime_value.pmiter.v.v_AB)}` }}<span
                  style="font-size: 12px;"> A-B</span></div>

            </div>
            <div class="col-4 text-center">
              <div style="font-size: 24px;color:#3ED79D">{{ `${two_digit(realtime_value.pmiter.v.v_BC)}` }}<span
                  style="font-size: 12px;"> B-C</span></div>

            </div>
            <div class="col-4 text-center">
              <div style="font-size: 24px;color:#3ED79D">{{ `${two_digit(realtime_value.pmiter.v.v_CA)}` }} <span
                  style="font-size: 12px;"> C-A</span></div>

            </div>
          </div>
          <div class="row">
            <div class="col-4 text-center">
              <div style="font-size: 14px;color:#979797">{{ `${two_digit(realtime_value.pmiter.v.v_AN)}` }} <span
                  style="font-size: 12px;">A</span></div>
              <div style="font-size: 12px;">Volts (V)</div>
            </div>
            <div class="col-4 text-center">
              <div style="font-size: 14px;color:#979797">{{ `${two_digit(realtime_value.pmiter.v.v_BN)}` }} <span
                  style="font-size: 12px;">B </span></div>
              <div style="font-size: 12px;">Volts (V)</div>
            </div>
            <div class="col-4 text-center">
              <div style="font-size: 14px;color:#979797">{{ `${two_digit(realtime_value.pmiter.v.v_CN)}` }} <span
                  style="font-size: 12px;">C</span></div>
              <div style="font-size: 12px;">Volts (V)</div>
            </div>
          </div>

          <div class="row mt-1">
            <div class="col-4 text-center">
              <div style="font-size: 14px;color:#979797">{{ `${two_digit(realtime_value.pmiter.v.v_ub_AN)}` }}% <span
                  style="font-size: 12px;">A (Unbalance)</span></div>
              <!-- <div style="font-size: 12px;color:#979797;">(Unbalance)</div> -->
              <!-- <div style="font-size: 12px;">Percent (%)</div> -->
            </div>
            <div class="col-4 text-center">
              <div style="font-size: 14px;color:#979797">{{ `${two_digit(realtime_value.pmiter.v.v_ub_BN)}` }}% <span
                  style="font-size: 12px;">B (Unbalance)</span></div>
              <!-- <div style="font-size: 12px;color:#979797;">(Unbalance)</div> -->
              <!-- <div style="font-size: 12px;">Percent (%)</div> -->
            </div>
            <div class="col-4 text-center">
              <div style="font-size: 14px;color:#979797">{{ `${two_digit(realtime_value.pmiter.v.v_ub_CN)}` }}% <span
                  style="font-size: 12px;">C (Unbalance)</span></div>
              <!-- <div style="font-size: 12px;color:#979797;">(Unbalance)</div> -->
              <!-- <div style="font-size: 12px;">Percent (%)</div> -->
            </div>
          </div>



        </div>

        <div>
          Current
          <div class="row">
            <div class="col-4 text-center">
              <div style="font-size: 24px;color:#52A0D3">{{ `${two_digit(realtime_value.pmiter.i.i_A)}` }} <span
                  style="font-size: 12px;">A</span></div>
              <div style="font-size: 12px;">Amp (A)</div>
            </div>
            <div class="col-4 text-center">
              <div style="font-size: 24px;color:#52A0D3">{{ `${two_digit(realtime_value.pmiter.i.i_B)}` }} <span
                  style="font-size: 12px;">B</span></div>
              <div style="font-size: 12px;">Amp (A)</div>
            </div>
            <div class="col-4 text-center">
              <div style="font-size: 24px;color:#52A0D3">{{ `${two_digit(realtime_value.pmiter.i.i_C)}` }} <span
                  style="font-size: 12px;">C</span></div>
              <div style="font-size: 12px;">Amp (A)</div>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-4 text-center">
              <div style="font-size: 14px;color:#979797">{{ `${two_digit(realtime_value.pmiter.i.i_ub_A)}` }}% <span
                  style="font-size: 12px;">A (Unbalance)</span></div>
              <!-- <div style="font-size: 12px;color:#979797;">(Unbalance)</div> -->
              <!-- <div style="font-size: 12px;">Percent (%)</div> -->
            </div>
            <div class="col-4 text-center">
              <div style="font-size: 14px;color:#979797">{{ `${two_digit(realtime_value.pmiter.i.i_ub_B)}` }}% <span
                  style="font-size: 12px;">B (Unbalance)</span></div>
              <!-- <div style="font-size: 12px;color:#979797;">(Unbalance)</div> -->
              <!-- <div style="font-size: 12px;">Percent (%)</div> -->
            </div>
            <div class="col-4 text-center">
              <div style="font-size: 14px;color:#979797">{{ `${two_digit(realtime_value.pmiter.i.i_ub_C)}` }}% <span
                  style="font-size: 12px;">C (Unbalance)</span></div>
              <!-- <div style="font-size: 12px;color:#979797;">(Unbalance)</div> -->
              <!-- <div style="font-size: 12px;">Percent (%)</div> -->
            </div>
          </div>
        </div>

        <div class=" mt-2 ">
          Power
          <div class="row">
            <div class="col-4 text-center">
              <div style="font-size: 24px;color:#8666e5">{{`${two_digit(realtime_value.pmiter.p.p_A)}`}} <span
                  style="font-size: 12px;">A</span></div>
            </div>
            <div class="col-4 text-center">
              <div style="font-size: 24px;color:#8666e5">{{`${two_digit(realtime_value.pmiter.p.p_B)}`}} <span
                  style="font-size: 12px;">B</span></div>
            </div>
            <div class="col-4 text-center">
              <div style="font-size: 24px;color:#8666e5">{{`${two_digit(realtime_value.pmiter.p.p_C)}`}} <span
                  style="font-size: 12px;">C</span></div>
            </div>
          </div>

          <div class="row">
            <div class="col-4 text-center">

              <div style="font-size: 12px;">kW</div>
            </div>
            <div class="col-4 text-center">

              <div style="font-size: 12px;">kW</div>
            </div>
            <div class="col-4 text-center">

              <div style="font-size: 12px;">kW</div>
            </div>
          </div>

          <div class="row">
            <div class="col-4 text-center">
              <div style="font-size: 14px;color:#979797">{{ `${two_digit(realtime_value.pmiter.p.app_A )}`}} <span
                  style="font-size: 12px;">Apparent A</span></div>
            </div>
            <div class="col-4 text-center">
              <div style="font-size: 14px;color:#979797">{{ `${two_digit(realtime_value.pmiter.p.app_B )}`}} <span
                  style="font-size: 12px;">Apparent B</span></div>
            </div>
            <div class="col-4 text-center">
              <div style="font-size: 14px;color:#979797">{{ `${two_digit(realtime_value.pmiter.p.app_C )}`}} <span
                  style="font-size: 12px;">Apparent C</span></div>
            </div>
          </div>
          <div class="row">
            <div class="col-4 text-center">

              <div style="font-size: 12px;">kVA</div>
            </div>
            <div class="col-4 text-center">

              <div style="font-size: 12px;">kVA</div>
            </div>
            <div class="col-4 text-center">

              <div style="font-size: 12px;">kVA</div>
            </div>
          </div>


          <div class="row">
            <div class="col-4 text-center">
              <div style="font-size: 14px;color:#979797">{{ `${two_digit(realtime_value.pmiter.p.rp_A )}`}} <span
                  style="font-size: 12px;">Reactive A</span></div>
            </div>
            <div class="col-4 text-center">
              <div style="font-size: 14px;color:#979797">{{`${two_digit(realtime_value.pmiter.p.rp_B)}`}} <span
                  style="font-size: 12px;">Reactive B</span></div>
            </div>
            <div class="col-4 text-center">
              <div style="font-size: 14px;color:#979797">{{`${two_digit(realtime_value.pmiter.p.rp_C)}`}} <span
                  style="font-size: 12px;">Reactive C</span></div>
            </div>
          </div>
          <div class="row">
            <div class="col-4 text-center">

              <div style="font-size: 12px;">kVAR</div>
            </div>
            <div class="col-4 text-center">

              <div style="font-size: 12px;">kVAR</div>
            </div>
            <div class="col-4 text-center">

              <div style="font-size: 12px;">kVAR</div>
            </div>
          </div>


        </div>

        <div>
         <div > Harmonic <span class="hover2"><img src="../assets/i.png" width="20" alt=""></span></div>
          <div class="row">
            <div class="col-4 text-center">
              <div style="font-size: 24px;color:rgb(195 76 201)">{{ `${two_digit(realtime_value.pmiter.thd.thd_A)}`}} <span
                  style="font-size: 12px;">A</span></div>
              <div style="font-size: 12px;">Percent (%)</div>
            </div>
            <div class="col-4 text-center">
              <div style="font-size: 24px;color:rgb(195 76 201)">{{ `${two_digit(realtime_value.pmiter.thd.thd_B)}`}} <span
                  style="font-size: 12px;">B</span></div>
              <div style="font-size: 12px;">Percent (%)</div>
            </div>
            <div class="col-4 text-center">
              <div style="font-size: 24px;color:rgb(195 76 201)">{{ `${two_digit(realtime_value.pmiter.thd.thd_C)}`}} <span
                  style="font-size: 12px;">C</span></div>
              <div style="font-size: 12px;">Percent (%)</div>
            </div>
          </div>
        </div>



      </div>
    </div>
    <div class="gradient mt-5">
      <div class="container">
        <div class="row justify-content-center  ">
          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-11 mt-3">
            <div class="card rounded-3 shadow-lg text-white"
              style="background-color: rgba(16, 16, 16, 0.497);height: 162px;">
              <div class="card-body">
                <h5 class="card-title" style="">Load</h5>
                <div class="row justify-content-center">
                  <div style="font-size: 16px;"><span >Frequency <span class="hoverf"><img src="../assets/iblack.svg" width="20" alt=""></span></span>  : {{ `${two_digit(realtime_value.pmiter.f)}` }} Hz <i
                      class="fa fa-houzz" aria-hidden="true"></i></div>
                  <div style="font-size: 16px;"> Power Factor <span class="hoverpf"><img src="../assets/iblack.svg" width="20" alt=""></span> : {{ `${two_digit(realtime_value.pmiter.pf.pf_tot)}` }}
                  </div>
                  <div class="text-start" style="font-size: 16px;"><span style="font-size: 16px;">Active Power:</span> {{ `${two_digit(realtime_value.pmiter.p.p_tot)}` }} kW</div>
                  <div class="text-start" style="font-size: 16px;"><span style="font-size: 16px;">Reactive Power:
</span>{{ `${two_digit(realtime_value.pmiter.p.rp_tot)}` }} kW</div>
                </div>

              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-11 mt-3">
            <div class="card rounded-3 shadow-lg text-white"
              style="background-color: rgba(16, 16, 16, 0.497);height: 162px;">
              <div class="card-body">
                <h5 class="card-title" style="">Select Source</h5>
                <div class="row justify-content-center">

                  <div class="text-center mt-3" style="font-size: 20px;">{{ realtime_value.selected_mode.mode }}</div>
                </div>
                <div class="text-center">
                  On use
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-11 mt-3">
            <div class="card rounded-3 shadow-lg text-white"
              style="background-color: rgba(16, 16, 16, 0.497);height: 162px;">
              <div class="card-body">
                <h5 class="card-title" style="">Generator</h5>

                <div class="row justify-content-center">
                  <div  v-if="realtime_value.gen.status==1"  class="circleminiload  col-9 ">
                    <div class="" style="font-size: 20px;">ON</div>
                    <div class="loading-circle-miniload"></div>
                  </div>
                  <div class="circleoff col-9 " v-else>  
                    <div  class="" style="font-size: 20px;">OFF</div>
                  </div>
                </div>

        <div class="row mt-3 justify-content-start">
          <u class="col-xl-5 col-lg-6 col-md-6 col-sm-6 col-6  text-start" style="font-size: 14px;max-width: 100px;">RUN
            TIME :</u>
          <div class="col-6 text-start" style="font-size: 12px;color: #2f928a;">
            <span style="color: #fbfbfb;">
                  {{ realtime_value.runtime.gen.runtimegen_hr }} Hour :{{ realtime_value.runtime.gen.runtimegen_min }} Minute
            </span>
            </div>
        </div>
                <!-- <div class="row mt-3">
                  <div class="text-start col-6">Time setting</div>
                  <div class="text-start col-6 text-end"><span
                      v-if="generatorTime.h<=10">0</span>{{ generatorTime.h }}:<span
                      v-if="generatorTime.m<=10">0</span>{{ generatorTime.m }} HH:MM</div>
                </div> -->
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-11 mt-3">
            <div class="card rounded-3 shadow-lg text-white"
              style="background-color: rgba(16, 16, 16, 0.497);height: 162px;">
              <div class="card-body">
                <h5 class="card-title" style="">Grid</h5>

                <div class="row justify-content-center">
                  <div class="circleminiload  col-9 "  v-if="realtime_value.grid.status==1">
                    <div v-if="realtime_value.grid.status==1" class="" style="font-size: 20px;">ON</div>
                    <!-- <div v-else-if="realtime_value.grid.status==0" class="" style="font-size: 20px;">OFF</div> -->
                   
                    <div class="loading-circle-miniload"></div>
                  </div>
                  <div class="col-9" v-else>
                    <div v-if="realtime_value.grid.status==0" class="" style="font-size: 20px;">OFF</div>
                    <div v-else class="text-center mt-3" style="font-size: 20px;">Not Available</div>
                   
                  </div>
                </div>
                <!-- <div class="row mt-3">
                  <div class="text-start col-6">Time setting</div>
                  <div class="text-start col-6 text-end">
                    <span v-if="gridTime.h<=10">0</span>{{ gridTime.h }}:<span
                      v-if="gridTime.m<=10">0</span>{{ gridTime.m }} HH:MM</div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="mt-2" style="height: 20vh;">

        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="gridModal" tabindex="-1" aria-labelledby="gridModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="gridModalLabel">TIME SETTING GRID</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <!-- <div>Time Setting</div> -->
          <div class="modal-body">

            <div class="row mt-3">
              <div class="col-6 text-center">
                <div class="row">
                  <div class="col-12">
                    <input v-model="gridTime.h" type="number" min="0" max="10" step="1"
                      style="height: 36px;font-size: 20px;" />
                  </div>


                </div>
              </div>
              <div class="col-6 text-center">
                <div class="row">
                  <div class="col-12">
                    <!-- <input type="number" min="0" max="10" step="1" value="1.0" style="height: 36px;font-size: 20px;"> -->
                    <input v-model="gridTime.m" type="number" min="0" max="10" step="1"
                      style="height: 36px;font-size: 20px;" />
                  </div>
                </div>
              </div>

            </div>
            <div class="row mt-3">
              <h4 class="col-6 text-center">
                Hour
              </h4>
              <h4 class="col-6 text-center">
                Minute
              </h4>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn" style="background-color: #482f92;color: white;">Save</button>
          </div>
        </div>
      </div>

    </div>

    <div class="modal fade" id="genModal" tabindex="-1" aria-labelledby="genModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="genModalLabel">TIME SETTING GENERATOR</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <!-- <div>Time Setting</div> -->
          <div class="modal-body">

            <div class="row mt-3">
              <div class="col-6 text-center">
                <div class="row">
                  <div class="col-12">
                    <input v-model="generatorTime.h" type="number" min="0" max="10" step="1"
                      style="height: 36px;font-size: 20px;" />
                  </div>


                </div>
              </div>
              <div class="col-6 text-center">
                <div class="row">
                  <div class="col-12">
                    <!-- <input type="number" min="0" max="10" step="1" value="1.0" style="height: 36px;font-size: 20px;"> -->
                    <input v-model="generatorTime.m" type="number" min="0" max="10" step="1"
                      style="height: 36px;font-size: 20px;" />
                  </div>
                </div>
              </div>

            </div>
            <div class="row mt-3">
              <h4 class="col-6 text-center">
                Hour
              </h4>
              <h4 class="col-6 text-center">
                Minute
              </h4>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn" style="background-color: #482f92;color: white;">Save</button>
          </div>
        </div>
      </div>

    </div>
  </body>

  </html>
</template>
<script>
  // var realtime_value;
  import {
    reactive
  } from '@vue/reactivity';

  import {
    socket
  } from "../services/socket.js";
  import axios from 'axios';
  export default {

    data() {
      return {
        Buffer: null,
        kkk:3,
        realtime_value: {
          mcu: {
            status: 0
          },
          runtime: {
            gen: {
              runtimegen_day: 0,
              runtimegen_hr: 0,
              runtimegen_min: 0
            },
            mcu: {
              runtimemcu_min: 0,
              runtimemcu_hr: 0,
              runtimemcu_day: 0
            },
            pcs:{
              fault_day:0,
              fault_hr:0,
              fault_min:0
            },
          },
          pmiter: {
            v: {
              v_ub_AN: 0,
              v_ub_BN: 0,
              v_ub_CN: 0,
              v_AB: 0,
              v_BC: 0,
              v_CA: 0,
              v_AN: 0,
              v_BN: 0,
              v_CN: 0
            },
            i: {
              i_A: 0,
              i_B: 0,
              i_C: 0,
              i_ub_A: 0,
              i_ub_B: 0,
              i_ub_C: 0,
            },
            p: {
              p_A: 0,
              p_B: 0,
              p_C: 0,
              p_tot: 0,
              rp_A: 0,
              rp_B: 0,
              rp_C: 0,
              rp_tot: 0,
              app_A: 0,
              app_B: 0,
              app_C: 0,
              app_tot: 0,
            },
            f: 0,
            pf: {
              pf_tot: 0,
            },
            thd: {
              thd_A: 0,
              thd_B: 0,
              thd_C: 0
            }
          },
          gen: {
            status: 0,
          },
          grid: {
            status: null,
          },
          inverter: {
            status: 0,
          },
          // powermeter: {
          //   status: 0,
          // },
       
          ats: {
            status: 0,
          },
          selected_mode: {
            mode: "N/A"
          },
        },

        power:{
            status:0
          },

        realtime: {
          gen: {
            status: 0
          }
        },
        gridTime: {
          h: 0,
          m: 0
        },
        generatorTime: {
          h: 0,
          m: 0
        },
        setting: {
          hour: 0,
          min: 0
        },
        series: [{
          name: 'Sales',
          data: [4, 4, 4, 4, 4, 4]
        }],
        chartOptions: {

          chart: {
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: false
            },
            // height: 350,
            type: 'line',
          },
          grid: {
            show: false,
          },
          forecastDataPoints: {
            // count: 7
          },
          //       legend: {
          // show: false,},
          stroke: {
            width: 2,
            curve: 'smooth'
          },
          xaxis: {
            // show: false,
            labels: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            type: 'datetime',
            categories: ['1/11/2000', '2/11/2000', '3/11/2000', '4/11/2000', '5/11/2000'],
            // tickAmount: 1,
            // labels: {
            //   formatter: function(value, timestamp, opts) {
            //     return opts.dateFormatter(new Date(timestamp), 'dd MMM')
            //   }
            // }
          },
          // title: {
          //   text: 'Forecast',
          //   align: 'left',
          //   style: {
          //     fontSize: "16px",
          //     color: '#666'
          //   }
          // },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              gradientToColors: ['#FDD835'],
              shadeIntensity: 1,
              type: 'horizontal',
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100]
            },
          },
          yaxis: {
            // min: -10,
            // max: 40

          }
        },
      };

    },
    methods: {
      two_digit(value){
        try{
          return value.toFixed(2);
        }catch(e){
          return value;
        }
        
      },
      logout() {
        localStorage.removeItem("token");
        localStorage.removeItem("role");
        this.$router.replace({
          path: "/login"
        });
      },
      checkStatusLogin() {
        try {
          var adminStatus = localStorage.getItem("token");
          if (adminStatus !== null) {
            try {
              this.checkLoginWithToken(adminStatus).then((statusCheck) => {
                if (statusCheck == true) {
                  this.$router.replace({
                    path: "/home"
                  });
                } else {
                  this.$router.replace({
                    path: "/login"
                  });
                }
              })
            } catch (error) {
              this.$router.replace({
                path: "/login"
              });
            }
          } else {
            this.$router.replace({
              path: "/login"
            });
          }
        } catch (error) {
          console.log(error);
          this.$router.replace({
            path: "/login"
          });
        }
      },

      checkLoginWithToken(token) {
        var config = {
          headers: {
            token: token,
          }
        };

        // Return a promise that will resolve to true or false based on the axios request
        return new Promise((resolve, reject) => {
          axios.get('https://banpu-energy-api.sgroup.io/penergy/auth/checktoken', config)
            .then(function (response) {
              console.log(response.data.data);
              if (response.data.data == "ok") {
                resolve(true);
              } else {
                resolve(false);
              }
            })
            .catch(function (error) {
              console.log(error);
              resolve(false); // Resolve the promise with false since login failed
            });
        });
      },
    },
    created() {
      this.checkStatusLogin();
      // console.log("/api")
    },
    mounted() {
      console.log('mounted!')
      socket.on("data", (data) => {
        console.log(data)

          this.Buffer = data.data.realtime_value
          console.log(data.data.realtime_value);
          console.log(data.data.setting_config);
          try {
            if (this?.Buffer) {
              this.realtime_value = reactive(data.data.realtime_value)
            }
          } catch (error) {

          }
          // this.realtime_value.
          // this.realtime_value=reactive(data.data.realtime_value)
          // this.realtime_value
          // console.log(this.realtime_value)
          // console.log(reactive(data.data.realtime_value))
          // this.greetings(data)
        },

      );

    },

  };
</script>

<style>
  /* table,
  th,
  td {
    border: 1px solid;
  } */

  .parallelogram {
    /* width: 100px;
	height: 60px; */
    transform: skew(-20deg);
    /* background: rgb(9, 189, 212); */
  }

  .svg-green {
    fill: green;
  }

  .dot-animation {
    /* width: 100px; */
    font-size: 20px;
    white-space: nowrap;
  }

  .dotanimation {
    color: #2f928a;
    animation: colorChange 1s infinite;
  }

  @keyframes colorChange {

    0%,
    33.33% {
      color: #6c47b0;
      /* Initial color */
    }

    66.66%,
    100% {
      color: #23ba8a;
      /* Color during the animation */
    }
  }

  .circle {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    border: 0.5px solid #482f929d;
    overflow: hidden;

  }

  .circle img {
    width: 80%;
    /* Adjust the size of the SVG image within the circle */
    height: auto;
  }

  .circleoff {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 0.5px solid #ffffff;
    overflow: hidden;
  }

  .circleoff img {
    width: 100%;
    /* Adjust the size of the SVG image within the circle */
    height: auto;
  }

  .circle2 {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    border: 0.5px solid #FA7667;
    overflow: hidden;

  }

  .circle2 img {
    width: 80%;
    /* Adjust the size of the SVG image within the circle */
    height: auto;
  }


  .loading-circle {
    position: absolute;
    width: 120%;
    height: 100%;
    border-radius: 90%;
    /* border: 0.8px solid transparent; */
    border-top-color: #482f92;
    /* Adjust the color of the loading circle */
    /* animation: rotate 1s linear infinite; */
    animation: growAndFade 3s infinite ease-out;
    background-color: #482f92;
    /* border-radius: 50%; */
    height: 100%;
    /* opacity: 0; */
    position: absolute;
    width: 100%;
  }

  .loading-circle-overload {
    position: absolute;
    width: 120%;
    height: 100%;
    border-radius: 90%;
    /* border: 0.8px solid transparent; */
    border-top-color: #922f2f;
    /* Adjust the color of the loading circle */
    /* animation: rotate 1s linear infinite; */
    animation: growAndFade 3s infinite ease-out;
    background-color: #922f2f;
    /* border-radius: 50%; */
    height: 100%;
    /* opacity: 0; */
    position: absolute;
    width: 100%;
  }

  @keyframes growAndFade {
    0% {
      opacity: 0.20;
      transform: scale(0);
    }

    /* 50% {
    opacity: 0.0;
    transform: scale(0);
  } */
    80% {
      opacity: 0.1;
      transform: scale(1);
    }

    100% {
      opacity: 0;
      transform: scale(1);
    }
  }

  .circlemini {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    /* border: 0.5px solid #482f929d; */
    overflow: hidden;
  }

  .circlemini img {
    width: 110%;
    /* Adjust the size of the SVG image within the circle */
    height: auto;
  }

  .loading-circle-mini {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 90%;
    border: 8.8px solid transparent;
    border-top-color: #ffffff;
    /* Adjust the color of the loading circle */
    animation: rotate 1s linear infinite;
  }

  .circleminiload {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 0.5px solid #482f929d;
    overflow: hidden;
  }

  .circleminiload img {
    width: 100%;
    /* Adjust the size of the SVG image within the circle */
    height: auto;
  }

  .loading-circle-miniload {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 90%;
    border: 1.8px solid transparent;
    border-top-color: #ffffff;
    /* Adjust the color of the loading circle */
    animation: rotate 1s linear infinite;
  }



  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  .gradient {
    width: 100%;
    /* height: 100px; */
    /* Adjust the height as needed */
    /* background: linear-gradient(to right, #23ba8a, #482f92); */
    background: linear-gradient(to right, rgba(0, 180, 156, 0.8), rgba(72, 47, 146, 0.9));
    background-size: 120% 100%;
    background-position: right;
    ;
  }
 /* Define the default styles for the <div> */
/* Define the default styles for the .hover1 element */
.hover1 {
    position: relative; /* Set position to relative for positioning the popup */
    cursor: pointer; /* Change cursor to pointer on hover (optional) */
    display: inline-block; /* Ensure the element takes only as much width as necessary */
  }

  /* Define the styles for the popup content (hidden by default) */
  .hover1::before {
    width: 150px;
   
     color: #3ED79D;
     font-size: 12px;
    position: absolute;
    left: 100%; /* Position it to the right of the .hover1 element */

    top: -3px; /* Align it with the top of the .hover1 element */
    background-color: #ffffff00; /* Background color of the popup */
    padding: 5px; /* Padding for spacing */
    display: none; /* Hide the popup by default */
    z-index: 1; /* Ensure the popup is on top of other content */
  }

  /* Define the hover styles for the .hover1 element */
  .hover1:hover::before {
    display: block; /* Show the popup when hovering */
    content: "Normal: 370-420 V   "; 
    
  }

  .hover1_1 {
    position: relative; /* Set position to relative for positioning the popup */
    cursor: pointer; /* Change cursor to pointer on hover (optional) */
    display: inline-block; /* Ensure the element takes only as much width as necessary */
  }

  /* Define the styles for the popup content (hidden by default) */
  .hover1_1::before {
    width: 150px;
    content: "Normal: 210-245 V"; /* Content of the popup */
     color: #8b8b8b ;
     font-size: 12px;
    position: absolute;
    left: 650%; /* Position it to the right of the .hover1 element */

    top: -3px; /* Align it with the top of the .hover1 element */
    background-color: #ffffff00; /* Background color of the popup */
    padding: 5px; /* Padding for spacing */
    display: none; /* Hide the popup by default */
    z-index: 1; /* Ensure the popup is on top of other content */
  }

  /* Define the hover styles for the .hover1 element */
  .hover1_1:hover::before {
    display: block; /* Show the popup when hovering */
  }

  .hover2 {
    position: relative; /* Set position to relative for positioning the popup */
    cursor: pointer; /* Change cursor to pointer on hover (optional) */
    display: inline-block; /* Ensure the element takes only as much width as necessary */
  }

  /* Define the styles for the popup content (hidden by default) */
  .hover2::before {
    width: 150px;
    content: "Normal: <15% "; /* Content of the popup */
     color: rgb(195 76 201);
     font-size: 12px;
    position: absolute;
    left: 100%; /* Position it to the right of the .hover1 element */

    top: -3px; /* Align it with the top of the .hover1 element */
    background-color: #ffffff00; /* Background color of the popup */
    padding: 5px; /* Padding for spacing */
    display: none; /* Hide the popup by default */
    z-index: 1; /* Ensure the popup is on top of other content */
  }

  /* Define the hover styles for the .hover1 element */
  .hover2:hover::before {
    display: block; /* Show the popup when hovering */
  }
  .hoverf {
    position: relative; /* Set position to relative for positioning the popup */
    cursor: pointer; /* Change cursor to pointer on hover (optional) */
    display: inline-block; /* Ensure the element takes only as much width as necessary */
  }

  /* Define the styles for the popup content (hidden by default) */
  .hoverf::before {
    width: 150px;
    content: "Normal: 50 Hz"; /* Content of the popup */
     color: #ffffff;
     font-size: 12px;
    position: absolute;
    left: 450%; /* Position it to the right of the .hover1 element */

    top: -3px; /* Align it with the top of the .hover1 element */
    background-color: #ffffff00; /* Background color of the popup */
    padding: 5px; /* Padding for spacing */
    display: none; /* Hide the popup by default */
    z-index: 1; /* Ensure the popup is on top of other content */
  }

  /* Define the hover styles for the .hover1 element */
  .hoverf:hover::before {
    display: block; /* Show the popup when hovering */
  }

  .hoverpf {
    position: relative; /* Set position to relative for positioning the popup */
    cursor: pointer; /* Change cursor to pointer on hover (optional) */
    display: inline-block; /* Ensure the element takes only as much width as necessary */
  }

  /* Define the styles for the popup content (hidden by default) */
  .hoverpf::before {
    width: 150px;
    content: "Normal: 0.8-1.0"; /* Content of the popup */
     color: #ffffff;
     font-size: 12px;
    position: absolute;
    left: 360%; /* Position it to the right of the .hover1 element */

    top: -3px; /* Align it with the top of the .hover1 element */
    background-color: #ffffff00; /* Background color of the popup */
    padding: 5px; /* Padding for spacing */
    display: none; /* Hide the popup by default */
    z-index: 1; /* Ensure the popup is on top of other content */
  }

  /* Define the hover styles for the .hover1 element */
  .hoverpf:hover::before {
    display: block; /* Show the popup when hovering */
  }

  
</style>