import { createRouter, createWebHistory } from 'vue-router'
import home from '../components/homepage';
import login from '../components/login';
import chart from '../components/chart'
import log from '../components/log'
import setting from '../components/setting'
import loading from '../components/loading'
import profile from '../components/profile'
// import mqtt from '../components/MqttClient'
const routes = [
  // {
  //   path: '/mqtt',
  //   name: 'Mqtt',
  //   component: mqtt
  // },
  {
    path: '/home',
    name: 'Home',
    component: home
  },
  {
    path: '/chart',
    name: 'chart',
    component: chart
  },
  {
    path: '/log',
    name: 'log',
    component: log
  },
  {
    path: '/setting',
    name: 'setting',
    component: setting
  },
  {
    path: '/profile',
    name: 'profile',
    component: profile
  },
  {
    path: '/login',
    name: 'Login',
    component: login
  },
  {
    path: '/',
    name: 'loading',
    component: loading
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router