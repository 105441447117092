<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
        <div class="container-fluid">
            <a class="navbar-brand" href="#" style="color: #482f92;font-size: 15.4px;">
                <img src="../assets/logo.png" width="200">
                <br>
                PRIORITIZING ENERGY SOURCE
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item px-3 parallelogram" style="height:50px;">
                        <a class="nav-link active  pt-3" aria-current="page" href="#" style="transform: skew(20deg);">
                            <router-link to="/home" style="text-decoration: none;color: black;">Home</router-link>
                        </a>
                    </li>
                    <li class="nav-item px-3 parallelogram" style="background-color: #482f92;height:50px;">
                        <a class="nav-link active text-white pt-3" aria-current="page" href="#"
                            style="transform: skew(20deg);">Chart</a>
                    </li>
                    <li class="nav-item px-3 parallelogram" style="height:50px;">
                        <a class="nav-link active  pt-3" aria-current="page" href="#" style="transform: skew(20deg);">
                            <router-link to="/log" style="text-decoration: none;color: black;">Notification
                            </router-link>
                        </a>
                    </li>
                    <li class="nav-item px-3 parallelogram" style="height:50px;">
                        <a class="nav-link active  pt-3" aria-current="page" href="#" style="transform: skew(20deg);">
                            <router-link to="/setting" style="text-decoration: none;color: black;">Setting</router-link>
                        </a>
                    </li>
                    <li class="nav-item px-3 parallelogram" style="height:50px;">
                        <a class="nav-link active  pt-3" aria-current="page" href="#" style="transform: skew(20deg);">
                            <router-link to="/profile" style="text-decoration: none;color: black;">Profile</router-link>
                        </a>
                    </li>
                </ul>
                <form class="d-flex">

                    <button class="btn btn-outline-danger" type="submit" @click="logout()"><span>

                        </span> logout</button>
                </form>
            </div>
        </div>
    </nav>

    <body class="mx-2">
        <div style="height: 80px;"></div>
        <h3>Chart Load Consumption</h3>

        <!-- <button @click="callChart('3m','voltage')">test v</button>
        <button @click="callChart('3m','current')">test c</button>
        <button @click="callChart('3m','power')">test p</button>
        <button @click="callChart('3m','harmonic')">test h</button> -->

        <h4 class="mt-3">Voltage</h4>
        <div class="row justify-content-start">
            <h5 class="col-xl-1  col-lg-2 col-md-2 col-sm-3 col-4 mt-2 ms-2">
                Select Time:
            </h5>

            <div class="col-xl-3 col-lg-4 col-md-8 col-sm-8 col-12 ">
                <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                    <input type="radio" class="btn-check" name="cc1" id="cc1_1" autocomplete="off"
                        @click="callChart('1hr','voltage')" checked>
                    <label class="btn btn-outline-dark" for="cc1_1">1 HR</label>

                    <input type="radio" class="btn-check" name="cc1" id="cc1_2" @click="callChart('1d','voltage')"
                        autocomplete="off">
                    <label class="btn btn-outline-dark" for="cc1_2">1 D</label>

                    <input type="radio" class="btn-check" name="cc1" id="cc1_3" @click="callChart('1w','voltage')"
                        autocomplete="off">
                    <label class="btn btn-outline-dark" for="cc1_3">1 W</label>

                    <input type="radio" class="btn-check" name="cc1" id="cc1_4" @click="callChart('1m','voltage')"
                        autocomplete="off">
                    <label class="btn btn-outline-dark" for="cc1_4">1 M</label>

                    <input type="radio" class="btn-check" name="cc1" id="cc1_5" @click="callChart('3m','voltage')"
                        autocomplete="off">
                    <label class="btn btn-outline-dark" for="cc1_5">3 M</label>

                    <input type="radio" class="btn-check" name="cc1" id="cc1_6" @click="callChart('6m','voltage')"
                        autocomplete="off">
                    <label class="btn btn-outline-dark" for="cc1_6">6 M</label>

                    <input type="radio" class="btn-check" name="cc1" id="cc1_7" autocomplete="off">
                    <label class="btn btn-outline-dark" data-bs-toggle="modal" data-bs-target="#exampleModal"
                        @click="datStateCallChart='voltage'" for="cc1_7">Date</label>

                </div>
            </div>
        </div>
        <div class="mt-2" id="chart">
            <apexchart type="area" height="350" :options="chartOptions_all_voltage" :series="series_all_voltage"
                ref="updateVoltageAll">
            </apexchart>
        </div>

        <h4 class="mt-3">Current</h4>
        <div class="row  justify-content-start">
            <h5 class="col-xl-1  col-lg-2 col-md-2 col-sm-3 col-4 mt-2 ms-2">
                Select Time:
            </h5>

            <div class="col-xl-3 col-lg-4 col-md-8 col-sm-8 col-12 ">
                <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                    <input type="radio" class="btn-check" name="cc2" id="cc2_1" autocomplete="off"
                        @click="callChart('1hr','current')" checked>
                    <label class="btn btn-outline-dark" for="cc2_1">1 HR</label>

                    <input type="radio" class="btn-check" name="cc2" id="cc2_2" autocomplete="off"
                        @click="callChart('1d','current')">
                    <label class="btn btn-outline-dark" for="cc2_2">1 D</label>

                    <input type="radio" class="btn-check" name="cc2" id="cc2_3" autocomplete="off"
                        @click="callChart('1w','current')">
                    <label class="btn btn-outline-dark" for="cc2_3">1 W</label>

                    <input type="radio" class="btn-check" name="cc2" id="cc2_4" autocomplete="off"
                        @click="callChart('1m','current')">
                    <label class="btn btn-outline-dark" for="cc2_4">1 M</label>

                    <input type="radio" class="btn-check" name="cc2" id="cc2_5" autocomplete="off"
                        @click="callChart('3m','current')">
                    <label class="btn btn-outline-dark" for="cc2_5">3 M</label>

                    <input type="radio" class="btn-check" name="cc2" id="cc2_6" autocomplete="off"
                        @click="callChart('6m','current')">
                    <label class="btn btn-outline-dark" for="cc2_6">6 M</label>

                    <input type="radio" class="btn-check" name="cc2" id="cc2_7" autocomplete="off">
                    <label class="btn btn-outline-dark" data-bs-toggle="modal" data-bs-target="#exampleModal"
                        @click="datStateCallChart='current'" for="cc2_7">Date</label>

                </div>
            </div>
        </div>
        <div class="mt-2" id="chart">
            <apexchart type="area" height="350" :options="chartOptions_all_current" :series="series_all_current"
                ref="updateCurrentAll">
            </apexchart>
        </div>

        <h4 class="mt-3">Power</h4>
        <div class="row  justify-content-start">
            <h5 class="col-xl-1  col-lg-2 col-md-2 col-sm-3 col-4 mt-2 ms-2">
                Select Time:
            </h5>
            <div class="col-xl-3 col-lg-4 col-md-8 col-sm-8 col-12 ">
                <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                    <input type="radio" class="btn-check" name="cc3" id="cc3_1" autocomplete="off"
                        @click="callChart('1hr','power')" checked>
                    <label class="btn btn-outline-dark" for="cc3_1">1 HR</label>

                    <input type="radio" class="btn-check" name="cc3" id="cc3_2" autocomplete="off"
                        @click="callChart('1d','power')">
                    <label class="btn btn-outline-dark" for="cc3_2">1 D</label>

                    <input type="radio" class="btn-check" name="cc3" id="cc3_3" autocomplete="off"
                        @click="callChart('1w','power')">
                    <label class="btn btn-outline-dark" for="cc3_3">1 W</label>

                    <input type="radio" class="btn-check" name="cc3" id="cc3_4" autocomplete="off"
                        @click="callChart('1m','power')">
                    <label class="btn btn-outline-dark" for="cc3_4">1 M</label>

                    <input type="radio" class="btn-check" name="cc3" id="cc3_5" autocomplete="off"
                        @click="callChart('3m','power')">
                    <label class="btn btn-outline-dark" for="cc3_5">3 M</label>

                    <input type="radio" class="btn-check" name="cc3" id="cc3_6" autocomplete="off"
                        @click="callChart('6m','power')">
                    <label class="btn btn-outline-dark" for="cc3_6">6 M</label>

                    <input type="radio" class="btn-check" name="cc3" id="cc3_7" autocomplete="off">
                    <label class="btn btn-outline-dark" data-bs-toggle="modal" data-bs-target="#exampleModal"
                        @click="datStateCallChart='power'" for="cc3_7">Date</label>


                </div>
            </div>
        </div>
        <div class="mt-2" id="chart">
            <apexchart type="area" height="350" :options="chartOptions_all_power" :series="series_all_power"
                ref="updatePowerAll">
            </apexchart>
        </div>

        <h4 class="mt-3">Frequency</h4>
        <div class="row  justify-content-start">
            <h5 class="col-xl-1  col-lg-2 col-md-2 col-sm-3 col-4 mt-2 ms-2">
                Select Time:
            </h5>
            <div class="col-xl-3 col-lg-4 col-md-8 col-sm-8 col-12 ">
                <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                    <input type="radio" class="btn-check" name="cc5" id="cc5_1" autocomplete="off"
                        @click="callChart('1hr','frequency')" checked>
                    <label class="btn btn-outline-dark" for="cc5_1">1 HR</label>

                    <input type="radio" class="btn-check" name="cc5" id="cc5_2" autocomplete="off"
                        @click="callChart('1d','frequency')">
                    <label class="btn btn-outline-dark" for="cc5_2">1 D</label>

                    <input type="radio" class="btn-check" name="cc5" id="cc5_3" autocomplete="off"
                        @click="callChart('1w','frequency')">
                    <label class="btn btn-outline-dark" for="cc5_3">1 W</label>

                    <input type="radio" class="btn-check" name="cc5" id="cc5_4" autocomplete="off"
                        @click="callChart('1m','frequency')">
                    <label class="btn btn-outline-dark" for="cc5_4">1 M</label>

                    <input type="radio" class="btn-check" name="cc5" id="cc5_5" autocomplete="off"
                        @click="callChart('3m','frequency')">
                    <label class="btn btn-outline-dark" for="cc5_5">3 M</label>

                    <input type="radio" class="btn-check" name="cc5" id="cc5_6" autocomplete="off"
                        @click="callChart('6m','frequency')">
                    <label class="btn btn-outline-dark" for="cc5_6">6 M</label>

                    <input type="radio" class="btn-check" name="cc5" id="cc5_7" autocomplete="off">
                    <label class="btn btn-outline-dark" data-bs-toggle="modal" data-bs-target="#exampleModal"
                        @click="datStateCallChart='frequency'" for="cc5_7">Date</label>


                </div>
            </div>
        </div>
        <div class="mt-2" id="chart">
            <apexchart type="area" height="350" :options="chartOptions_all_frequency" :series="series_all_frequency"
                ref="updateFrequencyAll">
            </apexchart>
        </div>


        <h4 class="mt-3">Power Factor</h4>
        <div class="row  justify-content-start">
            <h5 class="col-xl-1  col-lg-2 col-md-2 col-sm-3 col-4 mt-2 ms-2">
                Select Time:
            </h5>
            <div class="col-xl-3 col-lg-4 col-md-8 col-sm-8 col-12 ">
                <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                    <input type="radio" class="btn-check" name="cc6" id="cc6_1" autocomplete="off"
                        @click="callChart('1hr','powerfactor')" checked>
                    <label class="btn btn-outline-dark" for="cc6_1">1 HR</label>

                    <input type="radio" class="btn-check" name="cc6" id="cc6_2" autocomplete="off"
                        @click="callChart('1d','powerfactor')">
                    <label class="btn btn-outline-dark" for="cc6_2">1 D</label>

                    <input type="radio" class="btn-check" name="cc6" id="cc6_3" autocomplete="off"
                        @click="callChart('1w','powerfactor')">
                    <label class="btn btn-outline-dark" for="cc6_3">1 W</label>

                    <input type="radio" class="btn-check" name="cc6" id="cc6_4" autocomplete="off"
                        @click="callChart('1m','powerfactor')">
                    <label class="btn btn-outline-dark" for="cc6_4">1 M</label>

                    <input type="radio" class="btn-check" name="cc6" id="cc6_5" autocomplete="off"
                        @click="callChart('3m','powerfactor')">
                    <label class="btn btn-outline-dark" for="cc6_5">3 M</label>

                    <input type="radio" class="btn-check" name="cc6" id="cc6_6" autocomplete="off"
                        @click="callChart('6m','powerfactor')">
                    <label class="btn btn-outline-dark" for="cc6_6">6 M</label>

                    <input type="radio" class="btn-check" name="cc6" id="cc6_7" autocomplete="off">
                    <label class="btn btn-outline-dark" data-bs-toggle="modal" data-bs-target="#exampleModal"
                        @click="datStateCallChart='powerfactor'" for="cc6_7">Date</label>



                </div>
            </div>
        </div>

        <div class="mt-2" id="chart">
            <apexchart type="area" height="350" :options="chartOptions_all_powerfactor" :series="series_all_powerfactor"
                ref="updatePowerfactorAll">
            </apexchart>
        </div>

        <h4 class="mt-3">Harmonic</h4>
        <div class="row  justify-content-start">
            <h5 class="col-xl-1  col-lg-2 col-md-2 col-sm-3 col-4 mt-2 ms-2">
                Select Time:
            </h5>
            <div class="col-xl-3 col-lg-4 col-md-8 col-sm-8 col-12 ">
                <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                    <input type="radio" class="btn-check" name="cc4" id="cc4_1" autocomplete="off"
                        @click="callChart('1hr','harmonic')" checked>
                    <label class="btn btn-outline-dark" for="cc4_1">1 HR</label>

                    <input type="radio" class="btn-check" name="cc4" id="cc4_2" autocomplete="off"
                        @click="callChart('1d','harmonic')">
                    <label class="btn btn-outline-dark" for="cc4_2">1 D</label>

                    <input type="radio" class="btn-check" name="cc4" id="cc4_3" autocomplete="off"
                        @click="callChart('1w','harmonic')">
                    <label class="btn btn-outline-dark" for="cc4_3">1 W</label>

                    <input type="radio" class="btn-check" name="cc4" id="cc4_4" autocomplete="off"
                        @click="callChart('1m','harmonic')">
                    <label class="btn btn-outline-dark" for="cc4_4">1 M</label>

                    <input type="radio" class="btn-check" name="cc4" id="cc4_5" autocomplete="off"
                        @click="callChart('3m','harmonic')">
                    <label class="btn btn-outline-dark" for="cc4_5">3 M</label>

                    <input type="radio" class="btn-check" name="cc4" id="cc4_6" autocomplete="off"
                        @click="callChart('6m','harmonic')">
                    <label class="btn btn-outline-dark" for="cc4_6">6 M</label>

                    <input type="radio" class="btn-check" name="cc4" id="cc4_7" autocomplete="off">
                    <label class="btn btn-outline-dark" data-bs-toggle="modal" data-bs-target="#exampleModal"
                        @click="datStateCallChart='harmonic'" for="cc4_7">Date</label>


                </div>
            </div>
        </div>

        <div class="mt-2" id="chart">
            <apexchart type="area" height="350" :options="chartOptions_all_harmonic" :series="series_all_harmonic"
                ref="updateHarmonicAll">
            </apexchart>
        </div>
    </body>
    <!-- Modal -->
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-sm modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Select date {{ datStateCallChart }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <!-- <div>{{firstdayChart}}</div>
                    <div> {{ lastdayChart }}</div> -->
                    <div class="row">
                        <form class="text-center col-6 ">
                            <label for="firstday">Date : </label>
                            <input v-model="firstdayChart" type="date" id="firstday" name="firstday">
                        </form>
                        <!-- <div class="col-1 mt-4 text-center"></div> -->
                        <form class="text-center col-6 ">
                            <label for="lastday">Date : </label>
                            <input v-model="lastdayChart" type="date" id="lastday" name="lastday">
                        </form>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-dark" @click="callChart('select',datStateCallChart)"
                        data-bs-dismiss="modal">Save</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios';
    var voltageChartA = [];
    var voltageChartB = [];
    var voltageChartC = [];

    var currentChartA = [];
    var currentChartB = [];
    var currentChartC = [];

    var powerChartA = [];
    var powerChartB = [];
    var powerChartC = [];
    var powerChartTOT = [];

    var frequencyChart = [];
    var powerfactorChart = [];

    var harmonicChartA = [];
    var harmonicChartB = [];
    var harmonicChartC = [];

    export default {

        data() {
            return {

                series: [{
                    data: voltageChartA.slice()
                }],
                chartOptions: {
                    chart: {
                        height: 350,
                        type: 'line',
                        id: 'areachart-2'
                    },
                    annotations: {
                        yaxis: [{
                            y: 8200,
                            borderColor: '#00E396',
                            label: {
                                borderColor: '#00E396',
                                style: {
                                    color: '#fff',
                                    background: '#00E396',
                                },
                                text: 'Support',
                            }
                        }, {
                            y: 8600,
                            y2: 9000,
                            borderColor: '#000',
                            fillColor: '#FEB019',
                            opacity: 0.2,
                            label: {
                                borderColor: '#333',
                                style: {
                                    fontSize: '10px',
                                    color: '#333',
                                    background: '#FEB019',
                                },
                                text: 'Y-axis range',
                            }
                        }],
                        xaxis: [{
                            x: new Date('23 Nov 2017').getTime(),
                            strokeDashArray: 0,
                            borderColor: '#775DD0',
                            label: {
                                borderColor: '#775DD0',
                                style: {
                                    color: '#fff',
                                    background: '#775DD0',
                                },
                                text: 'Anno Test',
                            }
                        }, {
                            x: new Date('26 Nov 2017').getTime(),
                            x2: new Date('28 Nov 2017').getTime(),
                            fillColor: '#B3F7CA',
                            opacity: 0.4,
                            label: {
                                borderColor: '#B3F7CA',
                                style: {
                                    fontSize: '10px',
                                    color: '#fff',
                                    background: '#00E396',
                                },
                                offsetY: -10,
                                text: 'X-axis range',
                            }
                        }],
                        points: [{
                            x: new Date('01 Dec 2017').getTime(),
                            y: 8607.55,
                            marker: {
                                size: 8,
                                fillColor: '#fff',
                                strokeColor: 'red',
                                radius: 2,
                                cssClass: 'apexcharts-custom-class'
                            },
                            label: {
                                borderColor: '#FF4560',
                                offsetY: 0,
                                style: {
                                    color: '#fff',
                                    background: '#FF4560',
                                },

                                text: 'Point Annotation',
                            }
                        }, {
                            x: new Date('08 Dec 2017').getTime(),
                            y: 9340.85,
                            marker: {
                                size: 0
                            },
                            image: {
                                path: '../../assets/images/ico-instagram.png'
                            }
                        }]
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'straight'
                    },
                    grid: {
                        padding: {
                            right: 30,
                            left: 20
                        }
                    },
                    title: {
                        text: 'Line with Annotations',
                        align: 'left'
                    },
                    // labels: series.monthDataSeries1.dates,
                    xaxis: {
                        type: 'datetime',
                    },
                },
                lastdayChart: null,
                firstdayChart: null,
                datStateCallChart: null,
                series_all_voltage: [{
                        name: "Voltage A (V)",
                        data: voltageChartA.slice()
                    },
                    {
                        name: "Voltage B (V)",
                        data: voltageChartB.slice()
                    },
                    {
                        name: "Voltage C (V)",
                        data: voltageChartC.slice()
                    },
                ],
                chartOptions_all_voltage: {
                    chart: {
                        height: 380,
                        width: "100%",
                        type: 'line',
                        foreColor: '#000000',
                        toolbar: {
                            show: true,
                            offsetX: 0,
                            offsetY: 0,
                            tools: {
                                download: true,
                                selection: true,
                                zoom: true,
                                zoomin: true,
                                zoomout: true,
                                pan: true,
                                customIcons: []
                            },
                            export: {
                                csv: {
                                    filename: 'AllSensor',
                                    columnDelimiter: ',',
                                    headerCategory: 'Time',
                                    headerValue: 'value',
                                    dateFormatter(timestamp) {
                                        return new Date(timestamp)
                                    }
                                },
                                svg: {
                                    filename: 'AllSensor',
                                },
                                png: {
                                    filename: 'AllSensor',
                                }
                            },
                            autoSelected: 'zoom'
                        },


                    },
                    annotations: {
                        yaxis: [{
                            y: 210,
                            y2: 245,
                            borderColor: '#FA8267',
                            fillColor: '#ffffff00',
                            opacity: 1,
                            label: {
                                borderColor: '#333',
                                style: {
                                    fontSize: '10px',
                                    color: '#fff',
                                    background: '#FA8267',
                                },
                                text: 'Baseline',
                            }
                        }],
                    },
                    colors: ['#77B6EA', '#545454', '#70569E', ],
                    dataLabels: {
                        enabled: false,
                    },

                    stroke: {
                        show: true,
                        curve: 'smooth',
                        lineCap: 'butt',
                        colors: undefined,
                        width: 0.5,
                        dashArray: 0,
                    },
                    xaxis: {
                        type: 'datetime',
                        /* range: XAXISRANGE, */
                        labels: {
                            datetimeUTC: false,
                            datetimeFormatter: {
                                year: 'yyyy',
                                month: "MMM 'yy",
                                day: 'dd MMM',
                                hour: 'HH:mm:ss',
                            }
                        },
                    },
                    yaxis: {
                        // min: 200,
                        // max: 250,
                        decimalsInFloat: 2
                    },
                    tooltip: {
                        x: {
                            show: true,
                            format: 'dd MMM yy HH:mm:ss',
                            formatter: undefined,
                        }
                    },
                },

                series_all_current: [{
                        name: "Current A (A)",
                        data: currentChartA.slice()
                    },
                    {
                        name: "Current B (A)",
                        data: currentChartB.slice()
                    },
                    {
                        name: "Current C (A)",
                        data: currentChartC.slice()
                    },
                ],
                chartOptions_all_current: {
                    chart: {
                        height: 380,
                        width: "100%",
                        type: 'line',
                        foreColor: '#000000',

                        toolbar: {
                            show: true,
                            offsetX: 0,
                            offsetY: 0,
                            tools: {
                                download: true,
                                selection: true,
                                zoom: true,
                                zoomin: true,
                                zoomout: true,
                                pan: true,
                                customIcons: []
                            },
                            export: {
                                csv: {
                                    filename: 'AllSensor',
                                    columnDelimiter: ',',
                                    headerCategory: 'Time',
                                    headerValue: 'value',
                                    dateFormatter(timestamp) {
                                        return new Date(timestamp)
                                    }
                                },
                                svg: {
                                    filename: 'AllSensor',
                                },
                                png: {
                                    filename: 'AllSensor',
                                }
                            },
                            autoSelected: 'zoom'
                        },


                    },

                    colors: ['#77B6EA', '#545454', '#70569E', ],
                    dataLabels: {
                        enabled: false,
                    },

                    stroke: {
                        show: true,
                        curve: 'smooth',
                        lineCap: 'butt',
                        colors: undefined,
                        width: 0.5,
                        dashArray: 0,
                    },
                    xaxis: {
                        type: 'datetime',
                        /* range: XAXISRANGE, */
                        labels: {
                            datetimeUTC: false,
                            datetimeFormatter: {
                                year: 'yyyy',
                                month: "MMM 'yy",
                                day: 'dd MMM',
                                hour: 'HH:mm:ss',
                            }
                        },
                    },
                    yaxis: {
                        decimalsInFloat: 2
                    },
                    tooltip: {
                        x: {
                            show: true,
                            format: 'dd MMM yy HH:mm:ss',
                            formatter: undefined,
                        }
                    },
                },

                series_all_power: [{
                        name: "Power tot (kW)",
                        data: powerChartTOT.slice()
                    },
                    {
                        name: "Power A (kW)",
                        data: powerChartA.slice()
                    },
                    {
                        name: "Power B (kW)",
                        data: powerChartB.slice()
                    },
                    {
                        name: "Power C (kW)",
                        data: powerChartC.slice()
                    },

                ],
                chartOptions_all_power: {
                    chart: {
                        height: 380,
                        width: "100%",
                        type: 'line',
                        foreColor: '#000000',

                        toolbar: {
                            show: true,
                            offsetX: 0,
                            offsetY: 0,
                            tools: {
                                download: true,
                                selection: true,
                                zoom: true,
                                zoomin: true,
                                zoomout: true,
                                pan: true,
                                customIcons: []
                            },
                            export: {
                                csv: {
                                    filename: 'AllSensor',
                                    columnDelimiter: ',',
                                    headerCategory: 'Time',
                                    headerValue: 'value',
                                    dateFormatter(timestamp) {
                                        return new Date(timestamp)
                                    }
                                },
                                svg: {
                                    filename: 'AllSensor',
                                },
                                png: {
                                    filename: 'AllSensor',
                                }
                            },
                            autoSelected: 'zoom'
                        },


                    },

                    colors: ['#EB6451', '#77B6EA', '#545454', '#70569E'],
                    dataLabels: {
                        enabled: false,
                    },

                    stroke: {
                        show: true,
                        curve: 'smooth',
                        lineCap: 'butt',
                        colors: undefined,
                        width: 0.5,
                        dashArray: 0,
                    },
                    xaxis: {
                        type: 'datetime',
                        /* range: XAXISRANGE, */
                        labels: {
                            datetimeUTC: false,
                            datetimeFormatter: {
                                year: 'yyyy',
                                month: "MMM 'yy",
                                day: 'dd MMM',
                                hour: 'HH:mm:ss',
                            }
                        },
                    },
                    yaxis: {
                        decimalsInFloat: 2
                    },
                    tooltip: {
                        x: {
                            show: true,
                            format: 'dd MMM yy HH:mm:ss',
                            formatter: undefined,
                        }
                    },
                },

                series_all_frequency: [{
                    name: "Frequency (Hz)",
                    data: frequencyChart.slice()
                }],
                chartOptions_all_frequency: {
                    chart: {
                        height: 380,
                        width: "100%",
                        type: 'line',
                        foreColor: '#000000',

                        toolbar: {
                            show: true,
                            offsetX: 0,
                            offsetY: 0,
                            tools: {
                                download: true,
                                selection: true,
                                zoom: true,
                                zoomin: true,
                                zoomout: true,
                                pan: true,
                                customIcons: []
                            },
                            export: {
                                csv: {
                                    filename: 'AllSensor',
                                    columnDelimiter: ',',
                                    headerCategory: 'Time',
                                    headerValue: 'value',
                                    dateFormatter(timestamp) {
                                        return new Date(timestamp)
                                    }
                                },
                                svg: {
                                    filename: 'AllSensor',
                                },
                                png: {
                                    filename: 'AllSensor',
                                }
                            },
                            autoSelected: 'zoom'
                        },


                    },
                    annotations: {
                        yaxis: [{
                            y: 50,
                            // y2: 245,
                            borderColor: '#FA8267',
                            fillColor: '#ffffff00',
                            opacity: 1,
                            label: {
                                borderColor: '#333',
                                style: {
                                    fontSize: '10px',
                                    color: '#fff',
                                    background: '#FA8267',
                                },
                                text: 'Baseline',
                            }
                        }],
                    },
                    colors: ['#77B6EA'],
                    dataLabels: {
                        enabled: false,
                    },

                    stroke: {
                        show: true,
                        curve: 'smooth',
                        lineCap: 'butt',
                        colors: undefined,
                        width: 0.5,
                        dashArray: 0,
                    },
                    xaxis: {
                        type: 'datetime',
                        /* range: XAXISRANGE, */
                        labels: {
                            datetimeUTC: false,
                            datetimeFormatter: {
                                year: 'yyyy',
                                month: "MMM 'yy",
                                day: 'dd MMM',
                                hour: 'HH:mm:ss',
                            }
                        },
                    },
                    yaxis: {
                        decimalsInFloat: 2
                    },
                    tooltip: {
                        x: {
                            show: true,
                            format: 'dd MMM yy HH:mm:ss',
                            formatter: undefined,
                        }
                    },
                },

                series_all_powerfactor: [{
                    name: "Power factor",
                    data: powerfactorChart.slice()
                }],
                chartOptions_all_powerfactor: {
                    chart: {
                        height: 380,
                        width: "100%",
                        type: 'line',
                        foreColor: '#000000',

                        toolbar: {
                            show: true,
                            offsetX: 0,
                            offsetY: 0,
                            tools: {
                                download: true,
                                selection: true,
                                zoom: true,
                                zoomin: true,
                                zoomout: true,
                                pan: true,
                                customIcons: []
                            },
                            export: {
                                csv: {
                                    filename: 'AllSensor',
                                    columnDelimiter: ',',
                                    headerCategory: 'Time',
                                    headerValue: 'value',
                                    dateFormatter(timestamp) {
                                        return new Date(timestamp)
                                    }
                                },
                                svg: {
                                    filename: 'AllSensor',
                                },
                                png: {
                                    filename: 'AllSensor',
                                }
                            },
                            autoSelected: 'zoom'
                        },


                    },
                    annotations: {
                        yaxis: [{
                            y: 0.8,
                            y2: 1,
                            borderColor: '#FA8267',
                            fillColor: '#ffffff00',
                            opacity: 1,
                            label: {
                                borderColor: '#333',
                                style: {
                                    fontSize: '10px',
                                    color: '#fff',
                                    background: '#FA8267',
                                },
                                text: 'Baseline',
                            }
                        }],
                    },
                    colors: ['#77B6EA'],
                    dataLabels: {
                        enabled: false,
                    },

                    stroke: {
                        show: true,
                        curve: 'smooth',
                        lineCap: 'butt',
                        colors: undefined,
                        width: 0.5,
                        dashArray: 0,
                    },
                    xaxis: {
                        type: 'datetime',
                        /* range: XAXISRANGE, */
                        labels: {
                            datetimeUTC: false,
                            datetimeFormatter: {
                                year: 'yyyy',
                                month: "MMM 'yy",
                                day: 'dd MMM',
                                hour: 'HH:mm:ss',
                            }
                        },
                    },
                    yaxis: {
                        decimalsInFloat: 2
                    },
                    tooltip: {
                        x: {
                            show: true,
                            format: 'dd MMM yy HH:mm:ss',
                            formatter: undefined,
                        }
                    },
                },

                series_all_harmonic: [{
                        name: "Harmonic A (%)",
                        data: harmonicChartA.slice()
                    },
                    {
                        name: "Harmonic B (%)",
                        data: harmonicChartB.slice()
                    },
                    {
                        name: "Harmonic C (%)",
                        data: harmonicChartC.slice()
                    },
                ],
                chartOptions_all_harmonic: {
                    chart: {
                        height: 380,
                        width: "100%",
                        type: 'line',
                        foreColor: '#000000',

                        toolbar: {
                            show: true,
                            offsetX: 0,
                            offsetY: 0,
                            tools: {
                                download: true,
                                selection: true,
                                zoom: true,
                                zoomin: true,
                                zoomout: true,
                                pan: true,
                                customIcons: []
                            },
                            export: {
                                csv: {
                                    filename: 'AllSensor',
                                    columnDelimiter: ',',
                                    headerCategory: 'Time',
                                    headerValue: 'value',
                                    dateFormatter(timestamp) {
                                        return new Date(timestamp)
                                    }
                                },
                                svg: {
                                    filename: 'AllSensor',
                                },
                                png: {
                                    filename: 'AllSensor',
                                }
                            },
                            autoSelected: 'zoom'
                        },


                    },
                    annotations: {
                        yaxis: [{
                            y: 0,
                            y2: 15,
                            borderColor: '#FA8267',
                            fillColor: '#ffffff00',
                            opacity: 1,
                            label: {
                                borderColor: '#333',
                                style: {
                                    fontSize: '10px',
                                    color: '#fff',
                                    background: '#FA8267',
                                },
                                text: 'Baseline',
                            }
                        }],
                    },
                    colors: ['#77B6EA', '#545454', '#70569E', ],
                    dataLabels: {
                        enabled: false,
                    },

                    stroke: {
                        show: true,
                        curve: 'smooth',
                        lineCap: 'butt',
                        colors: undefined,
                        width: 0.5,
                        dashArray: 0,
                    },
                    xaxis: {
                        type: 'datetime',
                        /* range: XAXISRANGE, */
                        labels: {
                            datetimeUTC: false,
                            datetimeFormatter: {
                                year: 'yyyy',
                                month: "MMM 'yy",
                                day: 'dd MMM',
                                hour: 'HH:mm:ss',
                            }
                        },
                    },
                    yaxis: {
                        decimalsInFloat: 2
                    },
                    tooltip: {
                        x: {
                            show: true,
                            format: 'dd MMM yy HH:mm:ss',
                            formatter: undefined,
                        }
                    },
                },


            };
        },
        methods: {
            callChart: function (time, mode) {
                if (mode == 'voltage') {
                    var chartLoadReturnA = [];
                    var chartLoadReturnB = [];
                    var chartLoadReturnC = [];
                    try {
                        const article = {
                            "nameChart": "Voltage",
                            "mode": time,
                            "timeStart": this.firstdayChart,
                            "timeEnd": this.lastdayChart
                        };
                        const headers = {
                            "Content-Type": "application/json",
                            "token": localStorage.getItem("token")
                        };
                        axios.post("https://banpu-energy-api.sgroup.io/penergy/getdata/chart", article, {
                                headers
                            })
                            .then(response => {
                                var load = response.data;
                                console.log(load);
                                for (let index = 0; index < load.length; index++) {
                                    chartLoadReturnA.push([load[index]["time_b"], load[index]["v_an"]])
                                }
                                for (let index = 0; index < load.length; index++) {
                                    chartLoadReturnB.push([load[index]["time_b"], load[index]["v_bn"]])
                                }
                                for (let index = 0; index < load.length; index++) {
                                    chartLoadReturnC.push([load[index]["time_b"], load[index]["v_cn"]])
                                }
                                // console.log(response.data)
                                voltageChartA = chartLoadReturnA;
                                voltageChartB = chartLoadReturnB;
                                voltageChartC = chartLoadReturnC;
                                this.$refs.updateVoltageAll.updateSeries([{
                                        data: voltageChartA
                                    },
                                    {
                                        data: voltageChartB
                                    },
                                    {
                                        data: voltageChartC
                                    }
                                ]);
                            }).catch((e) => {
                                this.chartLoad = "Username or Password incurrect"
                            });
                    } catch (e) {};


                } else if (mode == 'current') {
                    var chartLoadReturnA = [];
                    var chartLoadReturnB = [];
                    var chartLoadReturnC = [];
                    try {
                        const article = {
                            "nameChart": "Current",
                            "mode": time,
                            "timeStart": this.firstdayChart,
                            "timeEnd": this.lastdayChart
                        };
                        const headers = {
                            "Content-Type": "application/json",
                            "token": localStorage.getItem("token")
                        };
                        axios.post("https://banpu-energy-api.sgroup.io/penergy/getdata/chart", article, {
                                headers
                            })
                            .then(response => {
                                var load = response.data;

                                for (let index = 0; index < load.length; index++) {
                                    chartLoadReturnA.push([load[index]["time_b"], load[index]["i_a"]])
                                }
                                for (let index = 0; index < load.length; index++) {
                                    chartLoadReturnB.push([load[index]["time_b"], load[index]["i_b"]])
                                }
                                for (let index = 0; index < load.length; index++) {
                                    chartLoadReturnC.push([load[index]["time_b"], load[index]["i_c"]])
                                }

                                currentChartA = chartLoadReturnA;
                                currentChartB = chartLoadReturnB;
                                currentChartC = chartLoadReturnC;
                                // console.log(response.data)
                                // console,log(currentChartA)
                                //  console.log(voltageChartA)
                                this.$refs.updateCurrentAll.updateSeries([{
                                        data: currentChartA
                                    },
                                    {
                                        data: currentChartB
                                    },
                                    {
                                        data: currentChartC
                                    }
                                ]);
                            }).catch((e) => {
                                this.chartLoad = "Username or Password incurrect"
                            });
                    } catch (e) {};


                } else if (mode == 'power') {
                    var chartLoadReturnA = [];
                    var chartLoadReturnB = [];
                    var chartLoadReturnC = [];
                    var chartLoadReturnTOT = [];
                    try {
                        const article = {
                            "nameChart": "Power",
                            "mode": time,
                            "timeStart": this.firstdayChart,
                            "timeEnd": this.lastdayChart
                        };
                        const headers = {
                            "Content-Type": "application/json",
                            "token": localStorage.getItem("token")
                        };
                        axios.post("https://banpu-energy-api.sgroup.io/penergy/getdata/chart", article, {
                                headers
                            })
                            .then(response => {
                                var load = response.data;
                                console.log(load)
                                for (let index = 0; index < load.length; index++) {
                                    chartLoadReturnA.push([load[index]["time_b"], load[index]["p_a"]])
                                }
                                for (let index = 0; index < load.length; index++) {
                                    chartLoadReturnB.push([load[index]["time_b"], load[index]["p_b"]])
                                }
                                for (let index = 0; index < load.length; index++) {
                                    chartLoadReturnC.push([load[index]["time_b"], load[index]["p_c"]])
                                }
                                for (let index = 0; index < load.length; index++) {
                                    // chartLoadReturnTOT.push([load[index]["time_b"], load[index]["p_a"]+load[index]["p_b"]+load[index]["p_c"]])
                                    chartLoadReturnTOT.push([load[index]["time_b"], load[index]["p_tot"]])
                                }
                                powerChartA = chartLoadReturnA;
                                powerChartB = chartLoadReturnB;
                                powerChartC = chartLoadReturnC;
                                powerChartTOT = chartLoadReturnTOT;
                                //  console.log(voltageChartA)
                                this.$refs.updatePowerAll.updateSeries([{
                                        data: powerChartTOT
                                    },
                                    {
                                        data: powerChartA
                                    },
                                    {
                                        data: powerChartB
                                    },
                                    {
                                        data: powerChartC
                                    },

                                ]);
                            }).catch((e) => {
                                this.chartLoad = "Username or Password incurrect"
                            });
                    } catch (e) {};


                } else if (mode == 'powerfactor') {
                    var chartLoadReturnA = [];
                    try {
                        const article = {
                            "nameChart": "PowerFactorTotal",
                            "mode": time,
                            "timeStart": this.firstdayChart,
                            "timeEnd": this.lastdayChart
                        };
                        const headers = {
                            "Content-Type": "application/json",
                            "token": localStorage.getItem("token")
                        };
                        axios.post("https://banpu-energy-api.sgroup.io/penergy/getdata/chart", article, {
                                headers
                            })
                            .then(response => {
                                var load = response.data;
                                for (let index = 0; index < load.length; index++) {
                                    chartLoadReturnA.push([load[index]["time_b"], load[index]["pf_tot"]])
                                }

                                powerfactorChart = chartLoadReturnA;
                                //  console.log(response.data[0]["time_b"])
                                // console.log(response.data)
                                this.$refs.updatePowerfactorAll.updateSeries([{
                                    data: powerfactorChart
                                }]);
                            }).catch((e) => {
                                this.chartLoad = "Username or Password incurrect"
                            });
                    } catch (e) {};
                } else if (mode == 'frequency') {
                    var chartLoadReturnA = [];
                    try {
                        const article = {
                            "nameChart": "Frequency",
                            "mode": time,
                            "timeStart": this.firstdayChart,
                            "timeEnd": this.lastdayChart
                        };
                        const headers = {
                            "Content-Type": "application/json",
                            "token": localStorage.getItem("token")
                        };
                        axios.post("https://banpu-energy-api.sgroup.io/penergy/getdata/chart", article, {
                                headers
                            })
                            .then(response => {
                                var load = response.data;
                                for (let index = 0; index < load.length; index++) {
                                    chartLoadReturnA.push([load[index]["time_b"], load[index]["f"]])
                                }

                                frequencyChart = chartLoadReturnA;
                                //  console.log(response.data[0]["time_b"])
                                // console.log(response.data)
                                this.$refs.updateFrequencyAll.updateSeries([{
                                    data: frequencyChart
                                }]);
                            }).catch((e) => {
                                this.chartLoad = "Username or Password incurrect"
                            });
                    } catch (e) {};
                } else if (mode == 'harmonic') {
                    var chartLoadReturnA = [];
                    var chartLoadReturnB = [];
                    var chartLoadReturnC = [];
                    try {
                        const article = {
                            "nameChart": "Harmonic",
                            "mode": time,
                            "timeStart": this.firstdayChart,
                            "timeEnd": this.lastdayChart
                        };
                        const headers = {
                            "Content-Type": "application/json",
                            "token": localStorage.getItem("token")
                        };
                        axios.post("https://banpu-energy-api.sgroup.io/penergy/getdata/chart", article, {
                                headers
                            })
                            .then(response => {
                                var load = response.data;
                                for (let index = 0; index < load.length; index++) {
                                    chartLoadReturnA.push([load[index]["time_b"], load[index]["thd_a"]])
                                }
                                for (let index = 0; index < load.length; index++) {
                                    chartLoadReturnB.push([load[index]["time_b"], load[index]["thd_b"]])
                                }
                                for (let index = 0; index < load.length; index++) {
                                    chartLoadReturnC.push([load[index]["time_b"], load[index]["thd_c"]])
                                }
                                harmonicChartA = chartLoadReturnA;
                                harmonicChartB = chartLoadReturnB;
                                harmonicChartC = chartLoadReturnC;
                                //  console.log(voltageChartA)
                                this.$refs.updateHarmonicAll.updateSeries([{
                                        data: harmonicChartA
                                    },
                                    {
                                        data: harmonicChartB
                                    },
                                    {
                                        data: harmonicChartC
                                    }
                                ]);
                            }).catch((e) => {
                                this.chartLoad = "Username or Password incurrect"
                            });
                    } catch (e) {};
                }
            },
            logout() {
                localStorage.removeItem("token");
                localStorage.removeItem("role");
                this.$router.replace({
                    path: "/login"
                });
            },
            checkStatusLogin() {
                try {
                    var adminStatus = localStorage.getItem("token");
                    if (adminStatus !== null) {
                        try {
                            this.checkLoginWithToken(adminStatus).then((statusCheck) => {
                                if (statusCheck == true) {
                                    this.$router.replace({
                                        path: "/chart"
                                    });
                                } else {
                                    this.$router.replace({
                                        path: "/login"
                                    });
                                }
                            })
                        } catch (error) {
                            this.$router.replace({
                                path: "/login"
                            });
                        }
                    } else {
                        this.$router.replace({
                            path: "/login"
                        });
                    }
                } catch (error) {
                    console.log(error);
                    this.$router.replace({
                        path: "/login"
                    });
                }
            },

            checkLoginWithToken(token) {
                var config = {
                    headers: {
                        token: token,
                    }
                };

                // Return a promise that will resolve to true or false based on the axios request
                return new Promise((resolve, reject) => {
                    axios.get('https://banpu-energy-api.sgroup.io/penergy/auth/checktoken', config)
                        .then(function (response) {
                            console.log(response.data.data);
                            if (response.data.data == "ok") {
                                resolve(true);
                            } else {
                                resolve(false);
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                            resolve(false); // Resolve the promise with false since login failed
                        });
                });
            },
        },
        created() {
            this.checkStatusLogin();

            this.callChart('1hr', 'voltage')
            this.callChart('1hr', 'current')
            this.callChart('1hr', 'power')
            this.callChart('1hr', 'frequency')
            this.callChart('1hr', 'powerfactor')
            this.callChart('1hr', 'harmonic')
        }
    };
</script>

<style>
    /* table, th, td {
    border: 1px solid;
  } */
    .parallelogram {
        /* width: 100px;
      height: 60px; */
        transform: skew(-20deg);
        /* background: rgb(9, 189, 212); */
    }

    #circle {
        width: 50px;
        height: 50px;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        border-radius: 25px;
        background: red;
    }
</style>