<template>

  <body>
    <section class="">
      <div style="background-color: hsl(0, 0%, 96%);height: 100vh;">
        <div style="height: 150px;">

        </div>
        <div class=" text-center text-lg-start">
          <div class="container">
            <div class="row justify-content-center">

              <div class="col-xl-5 ">

                <div class="card shadow-lg " style="width: 100%; border-radius: 35px;">
                  <div class="card-body px-5 " style="width: 100%;">
                    <!-- <img src="../assets/logo.png" alt=""
                      style="width: 350px;"> -->
                   <div class="row justify-content-center">
                    <img class=" mt-5" src="../assets/logo.png"
                      style="max-width: 300px;">
                   </div>
                    <div style="color: #482f92;font-size: 18px;" class="text-center">
                      PRIORITIZING ENERGY SOURCE
                    </div>
                    <!-- 2 column grid layout with text inputs for the first and last names -->
                    <h5 class="mt-2 mb-3 text-center" style="width: 100%;">
                      เข้าสู่ระบบ
                    </h5>

                    <!-- Email input -->
                    <div class="form-outline mb-3">
                      <input type="text" v-model="Web_username" class="form-control" />
                      <label class="form-label" for="form3Example3">Username</label>
                    </div>

                    <!-- Password input -->
                    <div class="form-outline ">
                      <input type="password" v-model="Web_password" class="form-control" />
                      <label class="form-label">Password</label>
                    </div>
                    <!-- <div class="text-end pb-4 me-2">
                      <router-link to="/forgotpassword" style="text-decoration: none;color:rgb(42, 165, 126)">
                        ลืมรหัสผ่าน
                      </router-link>
                    </div> -->

                    <div class="text-center mb-2  text-danger">{{ errorMessage }}</div>
                    <!-- Submit button -->
                    <div class="text-center mt-3">
                      <button type="submit" class="btn  btn-block mb-4 text-white" @click="loginUser()"
                        style="width: 200px;background-color: rgb(153, 165, 175);">
                        เข้าสู่ระบบ
                      </button>
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </body>
</template>

<script>
  // import axios from 'axios'
  import axios from 'axios';
  export default {
    data() {
      return {
        Web_username: '',
        Web_password: '',
        data: '',
        errorMessage: ''
      };
    },
    methods: {
      checkStatusLogin() {
                try {
                    var adminStatus = localStorage.getItem("token");
                    if (adminStatus !== null) {
                        try {
                            this.checkLoginWithToken(adminStatus).then((statusCheck) => {
                              if (statusCheck == true) {
                                    this.$router.replace({
                                        path: "/home"
                                    });
                                } else {
                                    this.$router.replace({
                                        path: "/login"
                                    });
                                }
                            })
                        } catch (error) {
                            this.$router.replace({
                                path: "/login"
                            });
                        }
                    } else {
                        this.$router.replace({
                            path: "/login"
                        });
                    }
                } catch (error) {
                    console.log(error);
                    this.$router.replace({
                            path: "/login"
                        });
                }
            },

            checkLoginWithToken(token) {
                var config = {
                    headers: {
                        token: token,
                    }
                };

                // Return a promise that will resolve to true or false based on the axios request
                return new Promise((resolve, reject) => {
                    axios.get('https://banpu-energy-api.sgroup.io/penergy/auth/checktoken', config)
                        .then(function (response) {
                            console.log(response.data.data);
                            if (response.data.data == "ok") {
                                resolve(true);
                            } else {
                                resolve(false);
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                            resolve(false); // Resolve the promise with false since login failed
                        });
                });
            },
      loginUser() {
        // this.errorMessage = "error.message";
        axios.post('https://banpu-energy-api.sgroup.io/penergy/auth/signin', {
            username: this.Web_username,
            password: this.Web_password
          })
          .then((res) => {
            console.log(res.data);
            this.success = true;
            localStorage.setItem("token", res.data.token)
            localStorage.setItem("role", res.data.role)
            this.$router.replace({
              path: "/home"
            });
          })
          .catch((error) => {
            console.error(error);
            this.errorMessage = "username or password wrong";
          });
      }

      // async loginUser() {
      //   const response = await fetch("https://app-api.sgroup.io/penergy/auth/signin", {
      //     method: "POST",
      //     mode: "no-cors",
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify({
      //       username: this.Web_username,
      //       password: this.Web_password
      //     }),
      //   });
      //   console.log(response);
      // },

//       async loginUser() {
//   try {
//     const response = await fetch("/api/penergy/auth/signin", {
//       method: "POST",
//       mode: "cors", // Use "cors" mode to handle the response properly
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({
//         username: this.Web_username,
//         password: this.Web_password
//       }),
//     });

//     // Check if the request was successful (status code 200-299)
//     if (response.ok) {
//       const data = await response.json(); // Parse the response data as JSON
//       console.log(data); // Do something with the response data
//     } else {
//       console.log("Request failed with status:", response.status);
//       // Handle other status codes as needed
//     }
//   } catch (error) {
//     console.log("Error occurred:", error);
//     // Handle errors here
//   }
// },

    },
    created() {
      this.checkStatusLogin();
    },
  };
</script>
<style>
  #rcorners1 {
    border-radius: 25px;
    background: #73AD21;
    padding: 20px;
    width: 200px;
    height: 150px;
  }
</style>